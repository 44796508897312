import { createContext, useContext } from "react";
import Cookies from "universal-cookie";
import { db, auth, functions } from "./firebase";

const cookies = new Cookies();
const _services = { cookies, db, auth, functions };
const DecouplerContext = createContext(_services);

// export type ServiceProviderProps = {
//   services?: typeof _services;
//   children: ReactElement | ReactElement[];
// };

export const ServiceProvider = ({ services, children }) => {
  return (
    <DecouplerContext.Provider value={services || _services}>
      {children}
    </DecouplerContext.Provider>
  );
};

export const useServices = () => {
  return useContext(DecouplerContext);
};
