export const IconButton = ({ onClick, disabled, active, children }) => (
  <button
    onClick={onClick}
    type="button"
    disabled={disabled}
    className={`w-8 h-8 ${
      active
        ? "bg-primary rounded-full text-white"
        : "text-primary disabled:text-text-medium"
    }`}
  >
    {children}
  </button>
);
