import { useState, useEffect, useMemo } from "react";
import { forEach, includes } from "lodash";
import { useHistory } from "react-router-dom";

import { useServices } from "services/index";
import { projects } from "../projects";

export const Welcome = () => {
  const history = useHistory();

  const { auth } = useServices();

  const [tokenResult, setTokenResult] = useState(false);

  useEffect(() => {
    auth.currentUser.getIdTokenResult().then((token) => setTokenResult(token));
  }, [auth.currentUser]);

  const projectLinks = useMemo(() => {
    const authorisedProjects = tokenResult.claims?.projects || [];

    let projectLinks = [];

    forEach(projects, (project) => {
      if (includes(authorisedProjects, project.id))
        projectLinks.push({
          label: project.name,
          to: project.route,
        });
    });
    return projectLinks;
  }, [tokenResult.claims?.projects]);

  return (
    <div className="w-full h-full flex items-center justify-center text-center">
      <div>
        <div className="text-2xl mb-4 font-bold">
          Welcome, {tokenResult?.claims?.name}
        </div>
        {!!projectLinks.length && (
          <>
            <div className="text-xl mb-4">Select a project to get started</div>
            <div className="flex flex-col items-center">
              {projectLinks.map((link, idx) => (
                <button
                  key={link.to}
                  className={`text-center py-2 w-full hover:text-accent ${
                    idx !== projectLinks.length - 1
                      ? "border-b border-borderColor"
                      : ""
                  }`}
                  onClick={() => history.push(link.to)}
                >
                  {link.label}
                </button>
              ))}
            </div>
          </>
        )}
        {!projectLinks.length && (
          <div className="text-xl">You don't have access to any projects</div>
        )}
      </div>
    </div>
  );
};
