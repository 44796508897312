import PulseLoader from "react-spinners/PulseLoader";

export const GraphCard = ({
  title,
  actionText,
  keyMetric,
  styles,
  children,
  loading,
  unflexedActions,
  actionComponent,
}) => {
  return (
    <div
      className={`p-4 bg-white rounded flex flex-col justify-between h-full ${
        styles || ""
      }`}
    >
      <div className="h-full">
        {!!keyMetric && (
          <div className="pb-4 flex items-center justify-end">
            <div className="font-light">{loading ? "-" : keyMetric}</div>
          </div>
        )}
        {!!title && (
          <div className="text-sm text-center uppercase pb-4">{title}</div>
        )}
        {!loading ? (
          children
        ) : (
          <div className="w-full h-80 pb-4 flex items-center justify-center">
            <PulseLoader color="#D0CFCD" loading={true} size={10} />
          </div>
        )}
      </div>
      {(!!actionText || !!actionComponent) && (
        <div
          className={`${
            unflexedActions ? "" : "flex"
          } items-center pt-4 border-t border-borderColor`}
        >
          {!!actionText && <div className="mr-4">{actionText}</div>}
          {!!actionComponent && (
            <div className="flex-1 ml-auto">{actionComponent}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default GraphCard;
