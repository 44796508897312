import { Route, Switch, Redirect } from "react-router-dom";
import { DashboardLayout } from "./Layout";
import { Welcome } from "./Welcome";
import { VRKit } from "./VRKit";
import { DiscoveryTour } from "./DiscoveryTour";
import { VirtualTour } from "./VirtualTour";
import UserManagement from "./UserManagement";
import { ClientManagement } from "./ClientManagement";

export const DashboardRouter = () => {
  return (
    <DashboardLayout>
      <Switch>
        <Route exact path="/" component={Welcome} />
        <Route exact path="/vrkit" component={VRKit} />
        <Route
          exact
          path="/woolworths/discovery-tour"
          component={DiscoveryTour}
        />
        <Route exact path="/woolworths/virtual-tour" component={VirtualTour} />
        <Route exact path="/client-management" component={ClientManagement} />
        <Route exact path="/user-management" component={UserManagement} />
        <Redirect to="/" />
      </Switch>
    </DashboardLayout>
  );
};
