import { ReactComponent as Hamburger } from "assets/icons/Hamburger.svg";
import Menu from "./Menu";

export const MobileNavigation = ({
  menuItems,
  bottomMenuItems,
  open,
  toggle,
}) => {
  return (
    <aside
      className={`bg-background-dark w-screen md:hidden px-4 overflow-y-hidden fixed left-0 right-0 top-0  ${
        open ? "h-screen" : "h-16"
      }`}
    >
      <div className="h-16 flex items-center justify-between">
        <div className="flex items-center">
          <Hamburger
            className="w-8 h-8 mr-4 cursor-pointer"
            fill="white"
            onClick={toggle}
          />
          <img
            alt="Think Digital Logo"
            src="/images/TD-Logo-White-TransparentBGx80.png"
            className="w-1/3"
          />
        </div>
        {/* <div className="flex items-center">
          <div className="pr-2 text-white uppercase">Admin</div>
          <div className="h-8 w-8 rounded-full bg-white" />
        </div> */}
      </div>
      <div className="px-2 py-6">
        <Menu menuItems={menuItems} onClick={toggle} />
        <Menu menuItems={bottomMenuItems} onClick={toggle} />
      </div>
    </aside>
  );
};

export default MobileNavigation;
