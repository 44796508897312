import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ResetPasswordForm } from "./Form";
import { useServices } from "services";
import PulseLoader from "react-spinners/PulseLoader";

export const ResetPassword = () => {
  const { auth } = useServices();

  const [email, setEmail] = useState(false);
  const [verifying, setVerifying] = useState(true);
  const actionCode = new URLSearchParams(useLocation().search).get("oobCode");

  useEffect(() => {
    setVerifying(true);
    auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        setEmail(email);
      })
      .catch(() => {
        setEmail();
      })
      .finally(() => {
        setVerifying(false);
      });
  }, [auth, actionCode]);

  return (
    <div className="w-72 flex flex-col items-start">
      {verifying && (
        <div className="self-center">
          <PulseLoader />
        </div>
      )}
      {!verifying && email && (
        <>
          <h1 className="text-4xl py-4 w-full text-center">Reset Password</h1>
          <ResetPasswordForm email={email} />
        </>
      )}
      {!verifying && !email && (
        <>
          <h1 className="text-3xl py-4 w-full text-center">
            Try resetting your password again
          </h1>
          <div>
            Your request to reset your password has expired or the link has
            already been used
          </div>
        </>
      )}
    </div>
  );
};
