import { GraphCard, PieChart } from "components";
import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { map, get } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import QuestionsSelect from "./QuestionsSelect";

export const SurveyAnswersDistribution = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ total: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const [questionFilter, setQuestionFilter] = useState();

  const query = useMemo(() => {
    return {
      dimensions: [
        {
          name: "customEvent:SurveyQuestion",
        },
        {
          name: "customEvent:SurveyAnswer",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                stringFilter: {
                  matchType: "EXACT",
                  value: "DiscoveryTours_SurveyAnswered",
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:SurveyQuestion",
                stringFilter: {
                  matchType: "EXACT",
                  value: questionFilter?.value,
                },
              },
            },
          ],
        },
      },
      metricAggregations: ["TOTAL"],
    };
  }, [questionFilter, fromDate, toDate]);

  useEffect(() => {
    setBusy(true);
    if (!questionFilter?.value) return setBusy(false);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");

        const _graph = map(data[0].rows, (dr) => ({
          id: dr.dimensionValues[1].value,
          value: Number(dr.metricValues[0].value),
        }));

        setData({ total: `Total answers: ${_total}`, graph: _graph });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, questionFilter, fromDate, toDate]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Survey answer distribution"
        actionText="Select a question"
        keyMetric={data.total}
        actionComponent={
          <QuestionsSelect
            value={questionFilter}
            setValue={setQuestionFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        }
      >
        <PieChart
          data={data.graph}
          margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
        />
      </GraphCard>
    </div>
  );
};
