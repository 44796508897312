// import { useRef } from "react";
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { DatePicker } from ".";

dayjs.extend(customParseFormat);

export const RangePicker = ({ fromDate, setFromDate, toDate, setToDate }) => {
  // let toRef = useRef();
  // let fromRef = useRef();

  // const setDaysAgo = (daysAgo) => {
  //   const today = new Date();
  //   setFromDate(dayjs(today).subtract(daysAgo, "day").toDate());
  //   setToDate(today);
  //   fromRef.getInput().blur();
  //   toRef.getInput().blur();
  // };

  // const CustomOverlay = ({ classNames, selectedDay, children, ...props }) => {
  //   return (
  //     <div className={classNames.overlayWrapper} {...props}>
  //       <div className={classNames.overlay}>
  //         {children}
  //         <div className="flex justify-center flex-wrap space-x-4 pb-4 px-2">
  //           <TextButton onClick={() => setDaysAgo(0)}>Today</TextButton>
  //           <TextButton onClick={() => setDaysAgo(7)}>Last 7 Days</TextButton>
  //           <TextButton onClick={() => setDaysAgo(30)}>Last 30 Days</TextButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  const CustomOverlay = ({ classNames, selectedDay, children, ...props }) => {
    return (
      <div
        className={classNames.overlayWrapper}
        style={{ marginLeft: -100 }}
        {...props}
      >
        <div className={classNames.overlay}>{children}</div>
      </div>
    );
  };

  return (
    <div className="flex items-center">
      <DatePicker
        // setRef={(el) => {
        //   fromRef = el;
        // }}
        // overlayComponent={CustomOverlay}
        value={fromDate}
        isDayBlocked={(day) => day > toDate}
        placeholder="From"
        dayPickerProps={{
          selectedDays: [fromDate, { from: fromDate, to: toDate }],
          modifiers: { start: fromDate, end: toDate },
          className: "RangePicker",
          // onDayClick: () => toRef.getInput().focus(),
        }}
        onChange={setFromDate}
      />

      <span className="mx-2 font-light"> — </span>
      <DatePicker
        // setRef={(el) => {
        //   toRef = el;
        // }}
        value={toDate}
        placeholder="To"
        overlayComponent={CustomOverlay}
        isDayBlocked={(day) => day < fromDate}
        dayPickerProps={{
          selectedDays: [fromDate, { from: fromDate, to: toDate }],
          modifiers: { start: fromDate, end: toDate },
          className: "RangePicker",
        }}
        onChange={setToDate}
      />
    </div>
  );
};
