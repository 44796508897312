// import { useCallback, useState, useEffect } from "react";
import {
  join,
  map,
  // keyBy
} from "lodash";

import { PrimaryButton, IconButton, Table } from "components";
import { ReactComponent as Edit } from "assets/icons/Edit.svg";
import { ReactComponent as Delete } from "assets/icons/Delete.svg";
// import { useServices } from "services/index";
import { projects } from "../projects";

export const UsersTable = ({
  users,
  setEditingUser,
  setDeletingUser,
  loading,
}) => {
  // const [projects, setProjects] = useState([]);

  // const { db } = useServices();

  // const loadProjects = useCallback(() => {
  //   return db
  //     .collection("projects")
  //     .get()
  //     .then((snapshot) => {
  //       const loadedProjects = [];
  //       snapshot.forEach((doc) =>
  //         loadedProjects.push({ id: doc.id, ...doc.data() })
  //       );
  //       setProjects(keyBy(loadedProjects, "id"));
  //     })
  //     .catch((err) => console.log(err));
  // }, [db]);

  // useEffect(() => loadProjects(), [loadProjects]);

  const columns = [
    { Header: "Username", accessor: "displayName" },
    {
      Header: "Projects",
      accessor: "projects",
      Cell: ({ row: { original } }) =>
        join(
          map(original.projects, (p) => projects[p]),
          // map(original.projects, (p) => projects[p] && projects[p].name),
          ", "
        ),
    },
    { Header: "Role", accessor: "role" },
    { Header: "Email", accessor: "email" },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => (
        <div className="flex items-center">
          <IconButton onClick={() => setEditingUser(original)}>
            <Edit className="w-4 h-4 mr-4" />
          </IconButton>
          <IconButton onClick={() => setDeletingUser(original)}>
            <Delete className="w-4 h-4 text-warning" />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="text-2xl mb-4 flex justify-between items-center">
        <div>Users</div>
        <PrimaryButton
          onClick={() => {
            setEditingUser({});
          }}
        >
          Add user
        </PrimaryButton>
      </div>
      <Table columns={columns} data={users} loading={loading} />
    </>
  );
};

export default UsersTable;
