import { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import { orderBy, take, takeRight, sumBy } from "lodash";
import { graphColors } from "components";

export const PieChart = ({
  data,
  margin = { top: 30, right: 30, bottom: 45, left: 30 },
  colors = graphColors,
  noLegend,
  topN,
}) => {
  const Legends = () => (
    <div className="flex flex-wrap justify-center pb-4">
      {processedData.map(({ id, value }, idx) => (
        <div
          key={id}
          className="flex items-center px-2 sm:px-3 py-2 rounded text-xs sm:text-sm"
        >
          <div
            style={{ background: colors[idx % colors.length] }}
            className="p-2 rounded-lg mr-2"
          />
          <span>
            {id} <span>({value})</span>
          </span>
        </div>
      ))}
    </div>
  );

  const processedData = useMemo(() => {
    const orderedData = orderBy(data, ["value"], ["desc"]);
    if (!topN || data.length <= topN) return orderedData;
    else {
      const topItems = take(data, topN);
      const restItems = takeRight(data, data.length - topN);
      return [...topItems, { id: "Other", value: sumBy(restItems, "value") }];
    }
  }, [data, topN]);

  return (
    <>
      <div className="w-full h-80 pb-4">
        {processedData.length < 1 && (
          <div className="h-full flex items-center justify-center text-text-medium">
            No data available
          </div>
        )}
        {!!processedData.length && (
          <ResponsivePie
            data={processedData}
            margin={margin}
            colors={colors}
            innerRadius={0.65}
            padAngle={0.7}
            borderWidth={4}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            tooltip={({ datum: { id, value, color } }) => (
              <div className="bg-background-light flex items-center p-3 rounded">
                <div
                  style={{ background: color }}
                  className={"p-2 rounded-lg mr-2"}
                />
                <span>
                  {id}: {value}
                </span>
              </div>
            )}
            borderColor={{ from: "color" }}
            fill={[{ match: "*", id: "gradient" }]}
          />
        )}
      </div>
      {!noLegend && <Legends />}
    </>
  );
};

export default PieChart;
