import { useState, useEffect, useMemo } from "react";
import dayjs from "dayjs";
import { groupBy, map, sumBy, maxBy } from "lodash";
import numeral from "numeral";

import { GraphCard, Table } from "components";
import { useServices } from "services";

export const VideosTable = ({ fromDate, toDate }) => {
  const [data, setData] = useState({ table: [], keyMetic: "" });
  const [loading, setLoading] = useState(false);

  const { functions } = useServices();

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: fromDate
            ? dayjs(fromDate).format("YYYY-MM-DD")
            : "30daysAgo",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "today",
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "EXACT",
            value: "DiscoveryTours_VideoPlayed",
          },
        },
      },
      orderBys: [
        {
          dimension: { orderType: "ALPHANUMERIC", dimensionName: "date" },
          desc: true,
        },
      ],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setLoading(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query })
      .then(({ data }) => {
        const formattedVideos = data[0].rows.map((row) => ({
          title: row.dimensionValues[0].value,
          date: dayjs(row.dimensionValues[1].value, "YYYYMMDD").toDate(),
          plays: row.metricValues[0].value,
        }));

        const groupedVideos = groupBy(formattedVideos, (vid) => vid.title);

        const tableData = map(groupedVideos, (g, key) => ({
          title: "VR Video",
          plays: sumBy(g, (vid) => Number(vid.plays)),
          downloads: 0,
          lastPlayed: maxBy(g, (vid) => new Date(vid.date).getTime()).date,
        }));

        const _keyMetric = `Total plays: ${numeral(sumBy(tableData, "plays"))
          .format("0,0")
          .replace(/,/g, " ")} | Total downloads: ${numeral(
          sumBy(tableData, "downloads")
        )
          .format("0,0")
          .replace(/,/g, " ")}`;

        setData({
          keyMetric: _keyMetric,
          table: tableData,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [functions, query, fromDate, toDate]);

  const columns = [
    { Header: "Video Title", accessor: "title" },
    { Header: "Plays", accessor: "plays" },
    { Header: "Downloads", accessor: "downloads" },
    {
      Header: "Last Played",
      accessor: ({ lastPlayed }) =>
        lastPlayed ? dayjs(lastPlayed).format("DD MMM YYYY") : "",
    },
  ];
  return (
    <GraphCard title="Videos Summary" keyMetric={data.keyMetric}>
      <div>
        <Table loading={loading} columns={columns} data={data.table} />
      </div>
    </GraphCard>
  );
};
