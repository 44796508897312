import { ForgotPasswordForm } from "./Form";

export const ForgotPassword = () => {
  return (
    <div className="w-72 flex flex-col items-start">
      <h1 className="text-4xl py-4 w-full text-center">Forgot Password</h1>
      <p className="text-sm py-4 w-full text-text-medium">
        Please enter the email address registered with us. You will receive a
        link to create a new password.
      </p>
      <ForgotPasswordForm />
    </div>
  );
};
