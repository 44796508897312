import { useEffect, useState, useCallback } from "react";

import { useServices } from "services/index";
import { ClientsTable } from "./ClientsTable";
import { ClientModal } from "./ClientModal";
import { DeleteClientModal } from "./DeleteClientModal";

export const ClientManagement = () => {
  const { db } = useServices();

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState();
  const [deletingClient, setDeletingClient] = useState();

  const getClientsList = useCallback(() => {
    setLoading(true);
    return db
      .collection("clients")
      .get()
      .then((snapshot) => {
        const clients = [];
        snapshot.forEach((doc) => {
          clients.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setClients(clients);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [db]);

  useEffect(() => getClientsList(), [getClientsList]);

  return (
    <div>
      <ClientsTable
        clients={clients}
        setEditingClient={setEditingClient}
        setDeletingClient={setDeletingClient}
        loading={loading}
      />
      <ClientModal
        client={editingClient}
        close={() => {
          setEditingClient(undefined);
          getClientsList();
        }}
      />
      <DeleteClientModal
        client={deletingClient}
        close={() => {
          setDeletingClient(undefined);
          getClientsList();
        }}
      />
    </div>
  );
};

export default ClientManagement;
