import { PublicRouter } from "public/Router";
import { DashboardRouter } from "Dashboard/Router";
import { useServices } from "services";
import { useEffect, useState } from "react";

export const Router = () => {
  const { auth } = useServices();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      // console.log(
      //   "🚀 ~ file: index.jsx ~ line 13 ~ auth.onAuthStateChanged ~ user.getIdTokenResult()",
      //   user.getIdTokenResult()
      // );
      setUser(user);
      setLoading(false);
    });
  }, [auth]);

  return (
    <div>
      {!loading && (
        <>
          {!user && <PublicRouter />}
          {user && <DashboardRouter />}
        </>
      )}
    </div>
  );
};
