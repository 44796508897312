import { forwardRef } from "react";
import { Field } from "formik";

export const FormInput = forwardRef(
  ({ name, type, disabled, handleChange, ...props }, ref) => (
    <Field
      as="input"
      name={name}
      type={type}
      disabled={disabled}
      innerRef={ref}
      {...props}
      className={`${disabled ? "cursor-not-allowed bg-muted" : ""}`}
    />
  )
);

export default FormInput;
