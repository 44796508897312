import { useState } from "react";
import dayjs from "dayjs";

import { ARActivitiesPerDay } from "./ARActivitiesPerDay";
import { ARActivitiesDistribution } from "./ARActivitiesDistribution";
import { VideosPlayedAndDownloaded } from "./VideosPlayedAndDownloaded";
import { UsersPerDevice } from "./UsersPerDevice";
import { Filters } from "./Filters";

export const DiscoveryTour = () => {
  const [fromDate, setFromDate] = useState(
    dayjs(new Date()).subtract(7, "day").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  return (
    <>
      <div className="pb-4 mb-6 flex justify-end border-b border-borderColor">
        <Filters
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      </div>
      <UsersPerDevice fromDate={fromDate} toDate={toDate} />
      <div className="flex flex-wrap">
        <div className="w-full flex flex-wrap p-4 my-4 border border-borderColor rounded-lg relative">
          <div className="absolute bg-background-light px-4 -top-1rem left-0 ml-2 md:ml-4 font-light">
            AR Activities
          </div>
          <div className="w-full lg:w-1/2">
            <ARActivitiesDistribution
              fromDate={fromDate}
              toDate={toDate}
              styles="lg:mr-2 h-full"
            />
          </div>
          <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
            <ARActivitiesPerDay
              fromDate={fromDate}
              toDate={toDate}
              styles="lg:ml-2 h-full"
            />
          </div>
        </div>
        <div className="w-full flex flex-wrap p-4 my-4 border border-borderColor rounded-lg relative">
          <div className="absolute bg-background-light px-4 -top-1rem left-0 ml-2 md:ml-4 font-light">
            Videos
          </div>
          <div className="w-full lg:w-1/2">
            <VideosPlayedAndDownloaded
              fromDate={fromDate}
              toDate={toDate}
              styles="lg:mr-2 h-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};
