import { ResponsiveLine } from "@nivo/line";
import { map, chunk, last } from "lodash";

import { graphColors } from "components";

export const LineChart = ({ data }) => {
  const Legend = () => {
    return (
      <div className="flex flex-wrap justify-center pb-4">
        {map(data, (dataSet, idx) => (
          <div
            key={dataSet.id}
            className="flex items-center px-3 py-2 rounded text-sm"
          >
            <div
              style={{ background: graphColors[idx % graphColors.length] }}
              className="p-2 rounded-lg mr-2"
            />
            <span>{dataSet.id}</span>
          </div>
        ))}
      </div>
    );
  };

  const ticks = () => {
    if (!data || !data[0]) return [];
    const tickSpacing =
      data[0].data.length >= 20 ? 5 : data[0].data.length >= 10 ? 2 : 1;
    return [
      ...map(chunk(data[0].data, tickSpacing), (c) => c[0].x),
      last(data[0].data).x,
    ];
  };

  return (
    <>
      <div className="w-full h-80 pb-4">
        <ResponsiveLine
          colors={graphColors}
          animate={false}
          data={data}
          margin={{ top: 30, right: 30, bottom: 45, left: 30 }}
          enableGridX={false}
          enableGridY={false}
          enableSlices="x"
          useMesh
          axisLeft={{
            tickValues: 5,
          }}
          axisBottom={{
            tickRotation: -90,
            tickValues: ticks(),
          }}
          theme={{
            axis: {
              ticks: {
                text: {
                  opacity: 0.5,
                },
              },
            },
          }}
          fill={[{ match: "*", id: "gradient" }]}
        />
      </div>
      <Legend />
    </>
  );
};

export default LineChart;
