export const FormField = ({ label, required, children }) => {
  return (
    <div className="w-full mb-4 text-sm">
      <label className="flex flex-col py-2">
        <div className="flex">
          <span className="">{label}</span>
          {required && <span className="pl-1">*</span>}
        </div>
        {children}
      </label>
    </div>
  );
};
