import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { uniqBy, map, split } from "lodash";
import dayjs from "dayjs";
import { Select } from "components";
import PulseLoader from "react-spinners/PulseLoader";

export const ActivitySelect = ({ value, setValue, fromDate, toDate }) => {
  const { functions } = useServices();
  const [busy, setBusy] = useState(true);
  const [options, setOptions] = useState([]);

  const query = useMemo(
    () => ({
      dimensions: [{ name: "eventName" }],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        filter: {
          stringFilter: {
            matchType: "BEGINS_WITH",
            value: "Scanned_AR_Activity_",
            caseSensitive: false,
          },
          fieldName: "eventName",
        },
      },
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "disctour", query })
      .then(({ data }) => {
        const _options = uniqBy(
          map(data[0].rows, (dr) => {
            const optionValue = dr.dimensionValues[0].value;
            const optionLabel = split(optionValue, "Scanned_AR_Activity_")[1];
            return {
              label: optionLabel,
              value: optionValue,
            };
          }),
          "value"
        );
        setOptions(_options);
        setValue(_options[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, setValue, query]);

  return busy ? (
    <PulseLoader color="#D0CFCD" size={10} />
  ) : (
    <Select options={options} value={value} onChange={setValue} />
  );
};

export default ActivitySelect;
