import { ErrorMessage } from "./ErrorMessage";

export const FormikFormField = ({
  name = "",
  hasErrorMessage = true,
  label,
  required,
  children,
}) => (
  <div className="py-2 w-full">
    <div className="flex items-center justify-between mb-1 text-sm">
      <div className="flex">
        <span>{label}</span>
        {required && <span className="pl-1">*</span>}
      </div>
      {hasErrorMessage && <ErrorMessage name={name} />}
    </div>
    {children}
  </div>
);
