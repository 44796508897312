import { useMemo } from "react";

import { TextButton, IconButton } from "components";

export const Pagination = ({
  gotoPage,
  previousPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  nextPage,
  pageIndex,
  pageSize,
  total,
}) => {
  const PageButtons = useMemo(() => {
    let pageIndexes = [pageIndex];
    if (pageIndex > 0) {
      pageIndexes.unshift(pageIndex - 1);
      if (pageIndex > 1) pageIndexes.unshift(pageIndex - 2);
    }

    if (pageIndex < pageCount - 1) {
      pageIndexes.push(pageIndex + 1);
      if (pageIndex < pageCount - 2) pageIndexes.push(pageIndex + 2);
    }

    return (
      <div className="flex justify-center md:justify-start my-2 md:my-2 items-center space-x-2">
        {pageIndexes.map((idx) => (
          <IconButton
            key={idx}
            onClick={() => gotoPage(idx)}
            active={pageIndex === idx}
          >
            {idx + 1}
          </IconButton>
        ))}
      </div>
    );
  }, [pageIndex, gotoPage, pageCount]);

  return (
    <div className="text-center md:text-left w-full py-2 text-sm">
      <div className="mb-2 flex-shrink-0">{`Showing ${
        pageSize * pageIndex + 1
      } to ${pageSize * pageIndex + pageSize} of ${total} entries`}</div>
      <div className="md:flex items-center w-full space-x-2">
        <TextButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          <div className="uppercase">First</div>
        </TextButton>
        <TextButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <div className="uppercase">Previous</div>
        </TextButton>
        {PageButtons}
        <TextButton onClick={() => nextPage()} disabled={!canNextPage}>
          <div className="uppercase">Next</div>
        </TextButton>
        <TextButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        >
          <div className="uppercase">Last</div>
        </TextButton>
      </div>
    </div>
  );
};
