import { ResponsiveBar } from "@nivo/bar";
import { useState, useEffect } from "react";
import { graphColors } from "components";
import { without, map, includes, chunk, last } from "lodash";

let barData = [];
for (var i = 0; i < 5; i++) {
  barData.push({
    yIndex: i,
    "Value #1": Math.round(Math.random() * 100),
    "Value #2": Math.round(Math.random() * 100),
    "Value #3": Math.round(Math.random() * 100),
    "Value #4": Math.round(Math.random() * 100),
  });
}

export const BarChart = ({
  colors,
  data = barData,
  legendKeys,
  groupMode,
  xTickRotation,
}) => {
  const [keys, setKeys] = useState({ all: [], filtered: [] });
  const _colors = colors || graphColors;

  const Legends = () => {
    return (
      <div className="flex flex-wrap justify-center pb-4">
        {map(keys.original, (key, idx) => {
          const disabled = !includes(keys.filtered, key);
          return (
            <button
              key={key}
              className="flex items-center mx-3 py-2 rounded text-sm"
            >
              <div
                style={{ background: _colors[idx % _colors.length] }}
                className="p-2 rounded-lg mr-2"
              />
              <span
                onClick={() => toggleKey(key)}
                className={disabled ? "line-through" : undefined}
              >
                {key}
              </span>
            </button>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    setKeys({
      original: legendKeys || without(Object.keys(data[0] || {}), "yIndex"),
      filtered: legendKeys || without(Object.keys(data[0] || {}), "yIndex"),
    });
  }, [data, setKeys, legendKeys]);

  const ticks = () => {
    const tickSpacing = data.length >= 20 ? 5 : data.length >= 10 ? 2 : 1;
    return [
      ...map(chunk(data, tickSpacing), (c) => c[0].yIndex),
      last(data).yIndex,
    ];
  };

  const toggleKey = (key) => {
    if (includes(keys.filtered, key))
      return setKeys({ ...keys, filtered: without(keys.filtered, key) });
    setKeys({ ...keys, filtered: [...keys.filtered, key] });
  };

  return (
    <>
      <div className="w-full h-80 pb-4">
        {data.length < 1 && (
          <div className="h-full flex items-center justify-center text-text-medium">
            No data available
          </div>
        )}
        {!!data.length && (
          <ResponsiveBar
            colors={_colors}
            data={data}
            groupMode={groupMode || "stacked"}
            keys={keys.filtered}
            margin={{ top: 30, right: 30, bottom: 45, left: 30 }}
            enableGridX={false}
            enableLabel={false}
            indexBy="yIndex"
            axisBottom={{
              tickRotation: xTickRotation !== undefined ? xTickRotation : -90,
              tickValues: ticks(),
            }}
            theme={{
              axis: {
                ticks: {
                  text: {
                    opacity: 0.5,
                  },
                },
              },
            }}
            fill={[{ match: "*", id: "gradient" }]}
          />
        )}
      </div>
      <Legends keys={keys} />
    </>
  );
};

export default BarChart;
