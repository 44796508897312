import { map, omit } from "lodash";
import { useEffect, useState, useCallback } from "react";

import { useServices } from "services/index";
import UsersTable from "./UsersTable";
import UserFormModal from "./UserFormModal";
import DeleteUserModal from "./DeleteUserModal";

export const UserManagement = () => {
  const { functions } = useServices();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState();
  const [deletingUser, setDeletingUser] = useState();

  const getUserList = useCallback(() => {
    setLoading(true);
    const getUserListFunction = functions.httpsCallable("api/users/getList");
    getUserListFunction()
      .then(({ data }) => {
        setUsers(
          map(data.users, (u) => ({
            ...omit(u, ["customClaims"]),
            projects: u.customClaims?.projects || [],
            role: u.customClaims?.role || "",
          }))
        );
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  }, [functions]);

  useEffect(() => getUserList(), [getUserList]);

  return (
    <div>
      <UsersTable
        users={users}
        setEditingUser={setEditingUser}
        setDeletingUser={setDeletingUser}
        loading={loading}
      />
      <UserFormModal
        user={editingUser}
        close={() => {
          setEditingUser(undefined);
          getUserList();
        }}
      />
      <DeleteUserModal
        user={deletingUser}
        close={() => {
          setDeletingUser(undefined);
          getUserList();
        }}
      />
    </div>
  );
};

export default UserManagement;
