import { GraphCard, BarChart } from "components";
import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { map, groupBy, find, each, flatMap, uniq, reduce, get } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import QuestionsSelect from "./QuestionsSelect";

export const SurveysAnsweredPerDay = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ total: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const [questionFilter, setQuestionFilter] = useState();

  const query = useMemo(() => {
    return {
      dimensions: [
        {
          name: "customEvent:SurveyQuestion",
        },
        {
          name: "customEvent:SurveyAnswer",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "customEvent:SurveyQuestion",
          stringFilter: {
            matchType: "EXACT",
            value: questionFilter?.value,
          },
        },
      },
      metricAggregations: ["TOTAL"],
    };
  }, [questionFilter, fromDate, toDate]);

  useEffect(() => {
    setBusy(true);
    if (!questionFilter?.value) return setBusy(false);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const byDate = groupBy(data[0].rows, ({ dimensionValues }) =>
          dayjs(dimensionValues[2].value, "YYYYMMDD").format("DD MMM")
        );
        const _data = map(byDate, (dateRows, date) => {
          const item = { yIndex: date };
          each(dateRows, (dr) => {
            item[dr.dimensionValues[1].value] =
              (item[dr.dimensionValues[1].value] || 0) +
              Number(dr.metricValues[0].value);
          });
          return item;
        });
        const legendKeys = uniq(flatMap(_data, (_d) => Object.keys(_d)));
        const initialData = reduce(
          legendKeys,
          (acc, key) => {
            acc[key] = 0;
            return acc;
          },
          {}
        );
        const allDays = [];
        const difference = dayjs(toDate).diff(dayjs(fromDate), "d");
        for (let i = difference; i >= 0; i--) {
          const yIndex = dayjs(toDate).subtract(i, "day").format("DD MMM");
          const found = find(_data, (gr) => gr.yIndex === yIndex);
          if (found)
            allDays.push({
              ...initialData,
              ...found,
            });
          else allDays.push({ ...initialData, yIndex });
        }

        setData({ total: `Total answers: ${_total}`, graph: allDays });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, questionFilter, fromDate, toDate]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Answers to survery questions"
        actionText="Select a question"
        keyMetric={data.total}
        actionComponent={
          <QuestionsSelect
            value={questionFilter}
            setValue={setQuestionFilter}
            fromDate={fromDate}
            toDate={toDate}
          />
        }
      >
        <BarChart data={data.graph} groupMode="grouped" />
      </GraphCard>
    </div>
  );
};
