import { useHistory, useLocation } from "react-router-dom";

import { ReactComponent as Home } from "assets/icons/Home.svg";
import { ReactComponent as Cog } from "assets/icons/Cog.svg";
import { ReactComponent as Logout } from "assets/icons/Logout.svg";
import { ReactComponent as Users } from "assets/icons/Users.svg";

const menuIcons = {
  Home,
  Cog,
  Logout,
  Users,
};

export const MenuItem = ({ onClick, to, icon, children, subItem }) => {
  const history = useHistory();
  const location = useLocation();

  const handleOnClick = () => {
    if (onClick) onClick();
    if (to) {
      history.push(to);
    }
  };

  const ItemIcon = icon && menuIcons[icon] ? menuIcons[icon] : menuIcons.Home;

  return (
    <div>
      <button
        onClick={handleOnClick}
        className={`uppercase font-semibold  text-left flex items-center ${
          subItem ? "py-1" : "py-3"
        } ${
          to && location.pathname === to ? "text-accent" : "text-text-light"
        } ${subItem ? "text-xs" : "text-sm"}`}
      >
        <div className="w-6 h-6 flex items-center justify-center flex-shrink-0 mr-4">
          <ItemIcon
            className={`${subItem ? "w-1/2 h-1/2" : "w-full h-full"}`}
          />
        </div>
        {children}
      </button>
    </div>
  );
};

export default MenuItem;
