import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { useServices } from "services";
import { FormField } from "components";

export const ForgotPasswordForm = () => {
  const { auth } = useServices();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const sendPasswordRecoveryEmail = (values, { setSubmitting }) => {
    setSubmitting(true);
    setError("");
    setMessage("");

    auth
      .sendPasswordResetEmail(values.email, {
        url: "https://dashboard.think.digital/login",
      })
      .then(() => {
        setMessage(
          "The email has been sent. Please follow the link in the email."
        );
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={sendPasswordRecoveryEmail}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <FormField label="Email" required={true}>
              <Field type="email" name="email" />
              <ErrorMessage
                className="text-error-800"
                name="email"
                component="div"
              />
            </FormField>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      {!!error && <div className="text-error-800 pt-4">{error}</div>}
      {!!message && <div className="text-primary pt-4">{message}</div>}
    </>
  );
};
