import { GraphCard, BarChart } from "components";
import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { map, sumBy, groupBy, find, get } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import { ActivitySelect } from "./ActivitySelect";

export const ARActivitiesPerDay = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ total: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const [activityFilter, setActivityFilter] = useState();

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "EXACT",
            value: activityFilter?.value,
          },
        },
      },
      metricAggregations: ["TOTAL"],
    }),
    [activityFilter, fromDate, toDate]
  );

  useEffect(() => {
    if (!activityFilter?.value) return;
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "disctour", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const byDate = groupBy(data[0].rows, ({ dimensionValues }) =>
          dayjs(dimensionValues[1].value, "YYYYMMDD").format("DD MMM")
        );
        const graph = map(byDate, (dateRows, date) => {
          const item = { yIndex: date };
          item[activityFilter.label] = sumBy(dateRows, (r) =>
            Number(r.metricValues[0].value)
          );
          return item;
        });
        const total = `Total activities: ${_total}`;

        const allDays = [];
        const difference = dayjs(toDate).diff(dayjs(fromDate), "d");
        for (let i = difference; i >= 0; i--) {
          const yIndex = dayjs(toDate).subtract(i, "day").format("DD MMM");
          allDays.push({
            yIndex,
            [activityFilter.label]:
              find(graph, { yIndex })?.[activityFilter.label] || 0,
          });
        }
        setData({ total, graph: allDays });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, activityFilter, query, fromDate, toDate]);

  const Select = useMemo(
    () => (
      <ActivitySelect
        value={activityFilter}
        setValue={setActivityFilter}
        fromDate={fromDate}
        toDate={toDate}
      />
    ),
    [activityFilter, fromDate, toDate]
  );

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="AR activities per day"
        actionText="Select an activity"
        keyMetric={data.total}
        actionComponent={Select}
      >
        <BarChart data={data.graph} groupMode="grouped" />
      </GraphCard>
    </div>
  );
};
