import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { uniqBy, map, split, sortBy } from "lodash";
import dayjs from "dayjs";
import { Select } from "components";
import PulseLoader from "react-spinners/PulseLoader";

export const HotspotSelect = ({ value, setValue, fromDate, toDate }) => {
  const { functions } = useServices();
  const [busy, setBusy] = useState(true);
  const [options, setOptions] = useState([]);

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "customEvent:label",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                stringFilter: {
                  matchType: "EXACT",
                  value: "click",
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:label",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: "$",
                },
              },
            },
            {
              notExpression: {
                filter: {
                  fieldName: "customEvent:label",
                  stringFilter: {
                    matchType: "CONTAINS",
                    value: "$ - Aisle",
                  },
                },
              },
            },
          ],
        },
      },
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "tourdisc", query })
      .then(({ data }) => {
        const _options = uniqBy(
          map(data[0].rows, (dr) => {
            const optionValue = dr.dimensionValues[1].value;
            const optionLabel = split(optionValue, "$ - ")[1];

            return {
              label: optionLabel,
              value: optionValue,
            };
          }),
          "label"
        );
        const sortedOptions = sortBy(_options, ["label"]);
        setOptions(sortedOptions);
        setValue(sortedOptions[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, setValue, query]);

  return busy ? (
    <PulseLoader color="#D0CFCD" size={10} />
  ) : (
    <Select options={options} value={value} onChange={setValue} />
  );
};

export default HotspotSelect;
