import { useState, useEffect, useMemo } from "react";
import { map, sumBy, groupBy, find, uniqBy, filter } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import { GraphCard, BarChart, Select } from "components";
import { useServices } from "services";

export const VideosPlayedAndDownloaded = ({ fromDate, toDate, styles }) => {
  const { functions } = useServices();
  const [data, setData] = useState({
    total: 0,
    reportData: [],
    graph: [],
    videoOptions: [],
  });
  const [videoFilter, setVideoFilter] = useState();
  const [graphData, setGraphData] = useState({ total: 0, data: [] });
  const [busy, setBusy] = useState(true);

  const query = useMemo(
    () => ({
      dimensions: [
        { name: "eventName" },
        { name: "date" },
        { name: "videoTitle" },
      ],
      metrics: [{ name: "eventCount" }],
      dateRanges: [
        {
          startDate: fromDate
            ? dayjs(fromDate).format("YYYY-MM-DD")
            : "30daysAgo",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "today",
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: { matchType: "CONTAINS", value: "_Video_" },
        },
      },
      orderBys: [
        {
          dimension: { orderType: "ALPHANUMERIC", dimensionName: "date" },
          desc: true,
        },
      ],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "disctour", query })
      .then(({ data }) => {
        const videosData = data[0].rows.map((row) => {
          const splitEventName = row.dimensionValues[0].value.split("_");
          return {
            ...row,
            videoEventType: `${splitEventName[0]}_${splitEventName[1]}`,
            videoName: splitEventName[2],
          };
        });

        const videoOptions = uniqBy(
          videosData.map((video) => {
            return { label: video.videoName, value: video.videoName };
          }),
          "value"
        );

        setData({
          reportData: videosData,
          videoOptions,
        });
        setVideoFilter(videoOptions[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query]);

  useEffect(() => {
    const filteredVideosData = data.reportData.filter(
      (datum) => datum.videoName === videoFilter?.value
    );
    const byDate = groupBy(filteredVideosData, ({ dimensionValues }) =>
      dayjs(dimensionValues[1].value, "YYYYMMDD").format("DD MMM")
    );

    const graph = map(byDate, (dateRows, date) => {
      const item = { yIndex: date };
      item["VideosPlayed"] = sumBy(
        filter(dateRows, (row) => row.videoEventType === "Played_Video"),
        (r) => Number(r.metricValues[0].value)
      );
      item["VideosDownloaded"] = sumBy(
        filter(dateRows, (row) => row.videoEventType === "Downloaded_Video"),
        (r) => Number(r.metricValues[0].value)
      );
      return item;
    });
    const total = numeral(
      sumBy(graph, (d) => d["VideosPlayed"]) +
        sumBy(graph, (d) => d["VideosDownloaded"])
    )
      .format("0,0")
      .replace(/,/g, " ");

    const allDays = [];
    const differenceInDays = dayjs(toDate).diff(dayjs(fromDate), "day");
    for (let i = 1; i <= differenceInDays + 1; i++) {
      const yIndex = dayjs(toDate)
        .subtract(differenceInDays - i + 1, "day")
        .format("DD MMM");

      allDays.push({
        yIndex,
        "Videos Played":
          find(graph, (gr) => gr.yIndex === yIndex)?.VideosPlayed || 0,
        "Videos Downloaded":
          find(graph, (gr) => gr.yIndex === yIndex)?.VideosDownloaded || 0,
      });
    }

    return setGraphData({
      total,
      data: allDays,
    });
  }, [videoFilter, data.reportData, fromDate, toDate]);

  const VideoSelect = useMemo(
    () => (
      <Select
        options={data.videoOptions}
        value={videoFilter}
        onChange={setVideoFilter}
      />
    ),
    [videoFilter, data.videoOptions]
  );

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Videos Played / Downloaded"
        actionText="Action"
        keyMetric={`Total videos: ${graphData.total}`}
        actionComponent={VideoSelect}
      >
        <BarChart data={graphData.data} groupMode="grouped" />
      </GraphCard>
    </div>
  );
};
