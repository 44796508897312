import { useState, useEffect, useMemo } from "react";
import { filter, sumBy } from "lodash";
import * as dayjs from "dayjs";

import { useServices } from "services";
import { InfoCard } from "components";

export const UsersPerDevice = ({ fromDate, toDate, styles }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ android: "0", iphone: "0", total: "0" });
  const [busy, setBusy] = useState(false);

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "operatingSystem",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: fromDate
            ? dayjs(fromDate).format("YYYY-MM-DD")
            : "30daysAgo",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "today",
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "EXACT",
            value: "first_open",
          },
        },
      },
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setData({ android: "-", iphone: "-", total: "-" });
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "disctour", query })
      .then(({ data }) => {
        const androidRows = filter(
          data[0].rows,
          (r) => r.dimensionValues[1].value === "Android"
        );

        const iphoneRows = filter(
          data[0].rows,
          (r) => r.dimensionValues[1].value === "iOS"
        );
        const android = sumBy(
          androidRows,
          (row) => Number(row.metricValues[0].value) || 0
        );
        const iphone = sumBy(
          iphoneRows,
          (row) => Number(row.metricValues[0].value) || 0
        );
        const item = {
          android: android.toString(),
          iphone: iphone.toString(),
          total: (android + iphone).toString(),
        };
        setData(item);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query]);

  return (
    <div className={styles}>
      <div className="mb-4 flex flex-wrap">
        <div className="w-1/2 lg:w-1/4 mb-2">
          <InfoCard
            loading={busy}
            heading={data.android}
            subheading="Android Users"
            styles="mr-2"
          />
        </div>
        <div className="w-1/2 lg:w-1/4 mb-2">
          <InfoCard
            loading={busy}
            heading={data.iphone}
            subheading="iPhone Users"
            styles="ml-2 lg:mx-2"
          />
        </div>
        <div className="w-1/2 lg:w-1/4 mb-2">
          <InfoCard
            loading={busy}
            heading={data.total}
            subheading="Total Users"
            styles="mr-2 lg:mx-2"
          />
        </div>
      </div>
    </div>
  );
};

// const fakeResult = [
//   {
//     rows: [
//       {
//         dimensionValues: [{ value: "first_open" }, { value: "Android" }],
//         metricValues: [{ value: "8" }],
//       },
//       {
//         dimensionValues: [{ value: "first_open" }, { value: "Iphone" }],
//         metricValues: [{ value: "4" }],
//       },
//     ],
//   },
// ];
