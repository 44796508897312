import { GraphCard, PieChart } from "components";
import { useServices } from "services";
import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import { map, get } from "lodash";

export const UsersPerDevice = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ keyMetric: "", graph: [] });
  const [busy, setBusy] = useState(true);

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "operatingSystem",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: fromDate
            ? dayjs(fromDate).format("YYYY-MM-DD")
            : "30daysAgo",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "today",
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "EXACT",
            value: "first_visit",
          },
        },
      },
      metricAggregations: ["TOTAL"],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "tourdisc", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const _graph = map(data[0].rows, (dr) => {
          const eventName = dr.dimensionValues[1].value;
          return {
            id: eventName,
            value: Number(dr.metricValues[0].value),
          };
        });
        setData({ total: `Total users: ${_total}`, graph: _graph });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, fromDate, toDate]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="User Device Distribution"
        keyMetric={data.total}
      >
        <PieChart
          data={data.graph}
          margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
        />
      </GraphCard>
    </div>
  );
};
