import ReactSelect from "react-select";

export const Select = ({ value, onChange, isOptionDisabled, options }) => {
  return (
    <ReactSelect
      value={value}
      onChange={onChange}
      isOptionDisabled={isOptionDisabled}
      options={options}
    />
  );
};
