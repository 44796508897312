import { LoginForm } from "./Form";

export const Login = () => {
  return (
    <div className="w-72 flex flex-col items-start">
      <h1 className="text-4xl py-4 w-full text-center">Login</h1>
      <LoginForm />
    </div>
  );
};
