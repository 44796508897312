import { useState, useEffect, useMemo } from "react";
import { GraphCard, PieChart } from "components";
import { useServices } from "services";
import { map, get } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

export const ARActivitiesDistribution = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [chartData, setChartData] = useState({ keyMetric: "", graph: [] });
  const [busy, setBusy] = useState(true);

  const query = useMemo(
    () => ({
      dimensions: [{ name: "eventName" }],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dimensionFilter: {
        filter: {
          stringFilter: {
            matchType: "BEGINS_WITH",
            value: "Scanned_AR_Activity",
            caseSensitive: false,
          },
          fieldName: "eventName",
        },
      },
      metricAggregations: ["TOTAL"],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "disctour", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const _graph = map(data[0].rows, (dr) => {
          const splitEventName = dr.dimensionValues[0].value.split("_");
          return {
            id: `${splitEventName[3]} ${
              splitEventName[4] ? splitEventName[4] : ""
            }`,
            value: Number(dr.metricValues[0].value),
          };
        });
        setChartData({ total: `Total activities: ${_total}`, graph: _graph });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="AR activities distribution"
        keyMetric={chartData.total}
      >
        <PieChart
          data={chartData.graph}
          margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
          topN={7}
        />
      </GraphCard>
    </div>
  );
};
