export const TextButton = ({ onClick, disabled, children }) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`text-accent  disabled:text-text-medium disabled:cursor-not-allowed ${
      !disabled ? "hover:underline" : ""
    }`}
  >
    {children}
  </button>
);
