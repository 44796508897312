export const projects = [
  { id: "vrkit", name: "VR Kit", route: "/vrkit" },
  {
    id: "disctour",
    name: "Woolworths",
    route: "/woolworths/discovery-tour",
    subItems: [
      { name: "Discovery Tour App", route: "/woolworths/discovery-tour" },
      { name: "Virtual Tour", route: "/woolworths/virtual-tour" },
    ],
  },
];
