import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { useServices } from "services";
import { FormField } from "components";

export const ResetPasswordForm = ({ email }) => {
  const { auth } = useServices();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);

  const resetPassword = (values, { setSubmitting }) => {
    setSubmitting(true);
    setError("");
    setSuccess(false);

    const actionCode = query.get("oobCode");

    auth
      .confirmPasswordReset(actionCode, values.passwordConfirm)
      .then(() => {
        setSuccess(true);
        auth
          .signInWithEmailAndPassword(email, values.passwordConfirm)
          .then(() => history.push("/"));
      })
      .catch((error) => {
        setSuccess(false);
        setError(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{ password: "", passwordConfirm: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.password) {
            errors.password = "Required";
          }
          if (!values.passwordConfirm) {
            errors.passwordConfirm = "Required";
          }
          if (values.passwordConfirm !== values.password) {
            errors.passwordConfirm = "Password does not match";
          }
          return errors;
        }}
        onSubmit={resetPassword}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <FormField label="Password" required={true}>
              <Field type="password" name="password" />
              <ErrorMessage
                className="text-error-800"
                name="password"
                component="div"
              />
            </FormField>
            <FormField label="Password Confirmation" required={true}>
              <Field type="password" name="passwordConfirm" />
              <ErrorMessage
                className="text-error-800"
                name="passwordConfirm"
                component="div"
              />
            </FormField>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      {!!error && <div className="text-error-800 pt-4">{error}</div>}
      {!!success && (
        <div className="text-primary pt-4">
          <div>Your password has been successfully reset. Redirecting...</div>
          <div className="mt-2">
            If you are waiting too long, please click&nbsp;
            <NavLink to="/login" className="underline">
              here
            </NavLink>
            &nbsp;to login
          </div>
        </div>
      )}
    </>
  );
};
