import { useMemo } from "react";
import PulseLoader from "react-spinners/PulseLoader";

export const SubmitButton = ({
  onClick,
  type,
  children,
  disabled,
  warning,
  loading,
}) => {
  const conditionalStyles = useMemo(() => {
    let styles = "";
    if (warning) {
      styles += "bg-warning ";
      if (!disabled)
        styles += "hover:text-warning hover:border-warning hover:bg-white";
    } else {
      styles += "bg-primary ";
      if (!disabled)
        styles += "hover:text-primary hover:border-primary hover:bg-white";
    }
    return styles;
  }, [disabled, warning]);

  return (
    <button
      type={type}
      onClick={onClick}
      className={`w-full rounded-full border p-3 text-white ${conditionalStyles}`}
      disabled={disabled}
    >
      {!loading && children}
      {loading && <PulseLoader color="#D0CFCD" loading={loading} size={10} />}
    </button>
  );
};
