import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { NavLink } from "react-router-dom";

import { useServices } from "services";
import { FormField } from "components";

export const LoginForm = () => {
  const { auth } = useServices();
  const [error, setError] = useState("");

  const login = (values, { setSubmitting }) => {
    setError("");

    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          return errors;
        }}
        onSubmit={login}
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <FormField label="Email" required={true}>
              <Field type="email" name="email" />
              <ErrorMessage
                className="text-error-800"
                name="email"
                component="div"
              />
            </FormField>
            <FormField label="Password" required={true}>
              <Field type="password" name="password" />
              <ErrorMessage
                className="text-error-800"
                name="password"
                component="div"
              />
            </FormField>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>
      {!!error && <div className="text-error-800 pt-4">{error}</div>}
      <div className="flex justify-center w-full">
        <NavLink className="text-primary text-sm mt-4" to="/forgot-password">
          Forgot password
        </NavLink>
      </div>
    </>
  );
};
