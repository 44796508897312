import { ReactComponent as Close } from "assets/icons/Close.svg";

import { IconButton } from "components";

export const Modal = ({ title, visible, close, width = "66vw", children }) => {
  return (
    visible && (
      <div className="fixed flex items-center justify-center inset-0 bg-background-modal z-10">
        <div
          style={{ width, maxHeight: "90vh", minHeight: "30vh" }}
          className="bg-white rounded-lg p-6 flex flex-col"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex items-center justify-between pb-4 border-b border-borderColor">
            <div className="text-2xl">{title}</div>
            <IconButton onClick={close}>
              <Close className="w-8 h-8" />
            </IconButton>
          </div>
          <div className="flex-grow flex flex-col justify-between">
            {children}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
