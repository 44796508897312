import { useCallback, useState } from "react";
import { omit } from "lodash";

import { Formik, Form, Field } from "formik";
import {
  FormField,
  FormikFormField,
  FormInput,
  Modal,
  SubmitButton,
} from "components";
import { useServices } from "services/index";

export const UserFormModal = ({ user, close }) => {
  const { functions } = useServices();
  const [error, setError] = useState("");

  const createUser = useCallback(
    (newUser) => {
      const createUserFunction = functions.httpsCallable("api/users/create");
      createUserFunction(omit(newUser, ["photoURL"]))
        .then(() => {
          close();
        })
        .catch((err) => setError(err.message));
    },
    [functions, close]
  );

  const saveUser = useCallback(
    (newUser) => {
      const updateUserFunction = functions.httpsCallable("api/users/update");
      updateUserFunction(omit(newUser, ["photoURL"]))
        .then(() => {
          close();
        })
        .catch((err) => setError(err.message));
    },
    [functions, close]
  );

  return (
    <Modal
      title={user?.uid ? "Edit User" : "Add User"}
      visible={!!user}
      close={close}
    >
      <Formik
        initialValues={user}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address";
          }
          if (!values.displayName) errors.displayName = "Required";
          return errors;
        }}
        onSubmit={(user, { setSubmitting }) => {
          const submitFunction = user?.uid ? saveUser(user) : createUser(user);

          return submitFunction.then(() => {
            setSubmitting(false);
          });
        }}
        validateOnMount
      >
        {({ isSubmitting }) => (
          <Form className="flex flex-col w-full">
            <FormikFormField label="Display Name" name="displayName" required>
              <FormInput name="displayName" />
            </FormikFormField>

            <FormikFormField label="Email" name="email" required>
              <FormInput name="email" />
            </FormikFormField>

            <FormikFormField label="Password" name="password" required>
              <FormInput name="password" type="password" />
            </FormikFormField>

            {user?.uid && (
              <div className="flex space-x-5">
                <FormField label="Role">
                  <div role="group" aria-labelledby="roles">
                    <label className="flex space-x-3">
                      <Field type="radio" name="role" value="admin" />
                      <div>Admin</div>
                    </label>
                    <label className="flex space-x-3">
                      <Field type="radio" name="role" value="viewer" />
                      <div>Viewer</div>
                    </label>
                  </div>
                </FormField>
                <FormField label="Clients">
                  <div role="group" aria-labelledby="roles">
                    <label className="flex space-x-3">
                      <Field type="checkbox" name="projects" value="vrkit" />
                      <div>VR Kit</div>
                    </label>
                    <label className="flex space-x-3">
                      <Field type="checkbox" name="projects" value="disctour" />
                      <div>Woolworths</div>
                    </label>
                  </div>
                </FormField>
              </div>
            )}
            <SubmitButton
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              {user?.uid ? "Save" : "Create"}
            </SubmitButton>
          </Form>
        )}
      </Formik>
      {!!error && (
        <div className="text-error-800 pt-4 text-center">{error}</div>
      )}
    </Modal>
  );
};

export default UserFormModal;
