import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

export const DatePicker = ({
  value,
  dayPickerProps,
  overlayComponent,
  isDayBlocked,
  placeholder,
  onChange,
  setRef,
}) => {
  const format = "DD/MM/YYYY";

  return (
    <DayPickerInput
      ref={(el) => {
        return setRef && setRef(el);
      }}
      overlayComponent={overlayComponent}
      value={value}
      format={format}
      inputProps={{
        placeholder: placeholder || format,
        readOnly: true,
        className: "border border-form-inputBorder rounded h-8 w-full px-2",
      }}
      dayPickerProps={{
        disabledDays: (day) => isDayBlocked && isDayBlocked(day),
        ...dayPickerProps,
      }}
      formatDate={(date) => {
        return dayjs(date).format(format);
      }}
      parseDate={(date) => {
        const dateTime = dayjs(date, format);
        if (!dateTime.isValid()) return undefined;

        return dateTime.toDate();
      }}
      onDayChange={(date) => {
        onChange && onChange(date);
      }}
    />
  );
};
