import { useEffect, useState } from "react";
import { includes, forEach } from "lodash";

import { projects } from "../projects";
import Navigation from "./Navigation";
import MobileNavigation from "./MobileNavigation";
import { useServices } from "services/index";

export const DashboardLayout = ({ children }) => {
  const { auth } = useServices();

  const [open, setOpen] = useState(false);
  const [tokenResult, setTokenResult] = useState(false);

  const logout = () => {
    auth.signOut().catch((error) => {
      console.log("🚀 ~ file: index.jsx ~ line 14 ~ logout ~ error", error);
      //TODO:  An error happened.
    });
  };

  useEffect(() => {
    auth.currentUser.getIdTokenResult().then((token) => setTokenResult(token));
  }, [auth.currentUser]);

  const menuItems = () => {
    const authorisedProjects = tokenResult.claims?.projects || [];
    const _menuItems = [];

    forEach(projects, (project) => {
      if (includes(authorisedProjects, project.id))
        _menuItems.push({
          label: project.name,
          to: project.route,
          onClick: () => setOpen(false),
          subItems: project.subItems
            ? project.subItems.map((subItem) => ({
                label: subItem.name,
                to: subItem.route,
                onClick: () => setOpen(false),
              }))
            : [],
        });
    });
    return _menuItems;
  };

  const bottomMenuItems = () => {
    const _menuItems = [];
    if (
      tokenResult.claims?.role === "admin" ||
      includes(
        ["terence@sveltestudios.com", "bruce@think.digital"],
        auth.currentUser.email
      )
    ) {
      // _menuItems.push({
      //   label: "Client Management",
      //   icon: "Clients",
      //   to: "/client-management",
      //   onClick: () => setOpen(false),
      // });
      _menuItems.push({
        label: "User Management",
        icon: "Users",
        to: "/user-management",
        onClick: () => setOpen(false),
      });
    }
    _menuItems.push({ label: "Logout", icon: "Logout", onClick: logout });
    return _menuItems;
    // {
    //   label: "Settings",
    //   icon: "Cog",
    //   subItems: [
    //     { label: "User Management", icon: "Users", to: "/user-management" },
    //   ],
    // },
  };
  return (
    <div
      className={`min-h-screen relative md:flex bg-background-light ${
        open ? "overflow-y-hidden" : undefined
      }`}
    >
      <div className="relative pt-16 md:pt-0 flex-grow overflow-y-auto md:pl-md-sidebar-wide lg:pl-lg-sidebar-wide xl:pl-sidebar-wide">
        <div className="p-6 h-full">{children}</div>
      </div>
      <Navigation
        menuItems={menuItems()}
        bottomMenuItems={bottomMenuItems()}
        username={tokenResult?.claims?.name}
      />
      <MobileNavigation
        menuItems={menuItems()}
        bottomMenuItems={bottomMenuItems()}
        open={open}
        toggle={() => setOpen(!open)}
      />
    </div>
  );
};
