import { BrowserRouter } from "react-router-dom";

import { ServiceProvider } from "services";
import { Router } from "Router";

import "./App.css";

function App() {
  return (
    <ServiceProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ServiceProvider>
  );
}

export default App;
