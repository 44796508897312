import { useCallback, useState } from "react";
import { omit } from "lodash";
import { Formik, Form } from "formik";

import { FormikFormField, FormInput, SubmitButton } from "components";
import { useServices } from "services/index";

export const ClientForm = ({ client, done }) => {
  const { db } = useServices();

  const [error, setError] = useState("");

  const createClient = useCallback(
    (newClient) => {
      return db
        .collection("clients")
        .add(newClient)
        .then(() => {
          done();
        })
        .catch((err) => setError(err.message));
    },
    [db, done]
  );

  const saveClient = useCallback(
    (newClient) => {
      return db
        .collection("clients")
        .doc(newClient.id)
        .update(omit(newClient, ["id"]))
        .then(() => {
          done();
        })
        .catch((err) => setError(err.message));
    },
    [db, done]
  );

  return (
    <>
      <Formik
        initialValues={{ ...client }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          return errors;
        }}
        onSubmit={(client) =>
          client?.id ? saveClient(client) : createClient(client)
        }
      >
        {({ isSubmitting, handleSubmit }) => (
          <Form onSubmit={handleSubmit} className="flex flex-col w-full">
            <FormikFormField label="Name" name="name" required>
              <FormInput name="name" />
            </FormikFormField>

            <div className="mt-4">
              <SubmitButton
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {client?.id ? "Save" : "Create"}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>
      {!!error && (
        <div className="text-error-800 pt-4 text-center">{error}</div>
      )}
    </>
  );
};
