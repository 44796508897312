import { useCallback, useState } from "react";

import { Modal, SubmitButton } from "components";
import { useServices } from "services/index";

export const DeleteUserModal = ({ user, close }) => {
  const { functions } = useServices();

  const [loading, setLoading] = useState(false);

  const deleteUser = useCallback(() => {
    setLoading(true);
    const deleteUserFunction = functions.httpsCallable("api/users/delete");
    deleteUserFunction(user?.uid)
      .then(() => {
        close();
        setLoading(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: DeleteUserModal.jsx ~ line 16 ~ deleteUser ~ err",
          err
        );
        setLoading(false);
      });
  }, [functions, close, user?.uid]);

  return (
    <Modal title="Delete User" visible={!!user} close={close}>
      <div className="flex-1 flex items-center justify-center">
        Are you sure you want to delete {user?.displayName} ({user?.email})?
      </div>
      <SubmitButton
        disabled={loading}
        loading={loading}
        warning
        className="bg-black"
        onClick={deleteUser}
      >
        Delete
      </SubmitButton>
    </Modal>
  );
};

export default DeleteUserModal;
