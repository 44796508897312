import { useCallback, useState, useEffect } from "react";

import { useServices } from "services/index";
import { PrimaryButton, IconButton, Table } from "components";
import { ReactComponent as Edit } from "assets/icons/Edit.svg";
import { ReactComponent as Delete } from "assets/icons/Delete.svg";

export const ClientsTable = ({
  clients,
  setEditingClient,
  setDeletingClient,
  loading,
}) => {
  const [projects, setProjects] = useState([]);
  const { db } = useServices();

  const getProjectsList = useCallback(() => {
    return db
      .collection("projects")
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => projects.push({ id: doc.id, ...doc.data() }));
        setProjects(projects);
      })
      .catch((err) =>
        console.log(
          "🚀 ~ file: ProjectsList.jsx ~ line 21 ~ getProjectsList ~ err",
          err
        )
      );
  }, [db]);

  useEffect(() => getProjectsList(), [getProjectsList]);

  const columns = [
    { Header: "Name", accessor: "name" },
    {
      Header: "Projects",
      accessor: ({ id }) =>
        projects.filter((project) => project.clientId === id).length,
    },
    {
      Header: "",
      id: "actions",
      Cell: ({ row: { original } }) => (
        <div className="flex items-center">
          <IconButton onClick={() => setEditingClient(original)}>
            <Edit className="w-4 h-4 mr-4" />
          </IconButton>
          <IconButton onClick={() => setDeletingClient(original)}>
            <Delete className="w-4 h-4 text-warning" />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="text-2xl mb-4 flex justify-between items-center">
        <div>Clients</div>
        <PrimaryButton
          onClick={() => {
            setEditingClient({ name: "" });
          }}
        >
          Add Client
        </PrimaryButton>
      </div>
      <Table columns={columns} data={clients} loading={loading} />
    </>
  );
};
