import PulseLoader from "react-spinners/PulseLoader";

export const LoadingOverlay = ({ loading }) => {
  return (
    <div
      className={`absolute flex h-full w-full transition-all pointer-events-none z-1 inset-0 bg-background-light ${
        loading ? "opacity-75" : "opacity-0"
      }`}
    >
      <div className="w-full h-full flex justify-center items-center text-white">
        <PulseLoader color="#D0CFCD" loading size={10} />
      </div>
    </div>
  );
};
