import Menu from "./Menu";
import { ReactComponent as User } from "assets/icons/User.svg";

export const Navigation = ({ menuItems, bottomMenuItems, username }) => {
  return (
    <aside className="bg-background-dark h-screen fixed left-0 top-0 md:w-md-sidebar-wide lg:w-lg-sidebar-wide xl:w-sidebar-wide flex-shrink-0 text-white hidden md:flex flex-col">
      <div className="flex justify-center uppercase font-light text-lg py-8">
        <img
          alt="Think Digital Logo"
          src="/images/TD-Logo-White-TransparentBGx80.png"
          className="w-3/4"
        />
      </div>
      <div className="border-t border-b border-borderColor mx-6 py-4 flex items-center">
        <div className="h-8 w-8 rounded-full bg-white flex items-center justify-center">
          <User className="w-6 h-6 text-text-medium" />
        </div>
        <div className="pl-4">{username}</div>
      </div>
      <div className="flex flex-col p-6 justify-between h-full">
        <div className="">
          <Menu menuItems={menuItems} />
        </div>
        <div className=" py-8">
          <Menu menuItems={bottomMenuItems} />
        </div>
      </div>
    </aside>
  );
};

export default Navigation;
