import { useCallback, useState } from "react";

import { SubmitButton, Modal } from "components";
import { useServices } from "services/index";

export const DeleteProjectModal = ({ project, done, close }) => {
  const { db } = useServices();

  const [loading, setLoading] = useState(false);

  const deleteProject = useCallback(() => {
    setLoading(true);
    return db
      .collection("projects")
      .doc(project?.id)
      .delete()
      .then(() => {
        done();
        setLoading(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: DeleteProject.jsx ~ line 16 ~ deleteClient ~ err",
          err
        );
        setLoading(false);
      });
  }, [db, done, project?.id]);

  return (
    <Modal title="Delete Project" visible={!!project} close={close}>
      {!!project?.users?.length && (
        <>
          <div className="flex-1 flex items-center justify-center py-4">
            This project ({project?.name}) still contains users. Remove all
            users from this project to delete it.
          </div>
          <SubmitButton onClick={close}>Close</SubmitButton>
        </>
      )}
      {!project?.users?.length && (
        <>
          <div className="flex-1 flex items-center justify-center py-4">
            Are you sure you want to delete {project?.name}?
          </div>
          <SubmitButton
            disabled={loading}
            loading={loading}
            warning
            onClick={deleteProject}
          >
            Delete
          </SubmitButton>
        </>
      )}
    </Modal>
  );
};
