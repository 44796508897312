import { GraphCard, BarChart } from "components";
import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { map, sumBy, groupBy, find, get } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import { HotspotSelect } from "./HotspotSelect";

export const HotspotsPerDay = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ total: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const [hotspotFilter, setHotspotFilter] = useState();

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "customEvent:label",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "customEvent:label",
                stringFilter: {
                  matchType: "ENDS_WITH",
                  value: hotspotFilter?.label,
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:label",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: "$",
                },
              },
            },
          ],
        },
      },
      metricAggregations: ["TOTAL"],
    }),
    [hotspotFilter, fromDate, toDate]
  );

  useEffect(() => {
    if (!hotspotFilter?.value) return;
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "tourdisc", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const byDate = groupBy(data[0].rows, ({ dimensionValues }) =>
          dayjs(dimensionValues[1].value, "YYYYMMDD").format("DD MMM")
        );
        const graph = map(byDate, (dateRows, date) => {
          const item = { yIndex: date };
          item[hotspotFilter.label] = sumBy(dateRows, (r) =>
            Number(r.metricValues[0].value)
          );
          return item;
        });
        const total = `Total hotspots: ${_total}`;

        const allDays = [];
        const difference = dayjs(toDate).diff(dayjs(fromDate), "d");
        for (let i = difference; i >= 0; i--) {
          const yIndex = dayjs(toDate).subtract(i, "day").format("DD MMM");
          allDays.push({
            yIndex,
            [hotspotFilter.label]:
              find(graph, { yIndex })?.[hotspotFilter.label] || 0,
          });
        }
        setData({ total, graph: allDays });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, hotspotFilter, query, fromDate, toDate]);

  const Select = useMemo(
    () => (
      <HotspotSelect
        value={hotspotFilter}
        setValue={setHotspotFilter}
        fromDate={fromDate}
        toDate={toDate}
      />
    ),
    [hotspotFilter, fromDate, toDate]
  );

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Hotspots per day"
        actionText="Select a hotspot"
        keyMetric={data.total}
        actionComponent={Select}
      >
        <BarChart data={data.graph} groupMode="grouped" />
      </GraphCard>
    </div>
  );
};
