import { Table, IconButton } from "components";
import { ReactComponent as Plus } from "assets/icons/Plus.svg";
import { ReactComponent as Edit } from "assets/icons/Edit.svg";
import { ReactComponent as Delete } from "assets/icons/Delete.svg";

export const ProjectsList = ({
  loading,
  newProject,
  setEditingProject,
  setDeletingProject,
  projects,
}) => {
  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Property ID", accessor: "propertyId" },
    { Header: "Slug", accessor: "slug" },
    {
      Header: "Users",
      accessor: ({ users }) => (users ? users.length : 0),
    },
    {
      Header: () => (
        <div className="flex items-center justify-center">
          <IconButton onClick={newProject}>
            <Plus className="w-6 h-6 mr-4" />
          </IconButton>
        </div>
      ),
      id: "actions",
      Cell: ({ row: { original } }) => (
        <div className="flex items-center justify-center">
          <IconButton onClick={() => setEditingProject(original)}>
            <Edit className="w-4 h-4 mr-4" />
          </IconButton>
          <IconButton onClick={() => setDeletingProject(original)}>
            <Delete className="w-4 h-4 text-warning" />
          </IconButton>
        </div>
      ),
    },
  ];
  return <Table columns={columns} data={projects} loading={loading} />;
};
