import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { PublicLayout } from "./Layout";
import { Login } from "./Login";
import { ForgotPassword } from "./ForgotPassword";
import { ResetPassword } from "./ResetPassword";

export const PublicRouter = () => {
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const transformLocation = (location) => {
    if (location.pathname !== "/email-action") return location;
    else {
      const mode = query.get("mode");
      if (mode === "resetPassword")
        return {
          ...location,
          pathname: "/email-reset-password",
        };
      return location;
    }
  };
  return (
    <PublicLayout>
      <Switch location={transformLocation(location)}>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/reset-password" component={ResetPassword} />
        <Redirect
          from="/email-reset-password"
          to={{ ...location, pathname: "/reset-password" }}
        />
        <Redirect to="/login" />
      </Switch>
    </PublicLayout>
  );
};
