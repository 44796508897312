import { GraphCard, BarChart } from "components";
import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { map, forEach, groupBy, uniq } from "lodash";
import dayjs from "dayjs";

import QuestionsSelect from "./QuestionsSelect";

export const SurveyGroupedAnswers = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ total: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const [questionFilterOne, setQuestionFilterOne] = useState();
  const [questionFilterTwo, setQuestionFilterTwo] = useState({
    label: "",
    value: "",
  });

  const query = useMemo(() => {
    return {
      dimensions: [
        {
          name: "customEvent:SurveyQuestion",
        },
        {
          name: "customEvent:SurveyAnswer",
        },
        {
          name: "customEvent:AnswerUUID",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                stringFilter: {
                  matchType: "EXACT",
                  value: "DiscoveryTours_SurveyAnswered",
                },
              },
            },
            {
              orGroup: {
                expressions: [
                  {
                    filter: {
                      fieldName: "customEvent:SurveyQuestion",
                      stringFilter: {
                        matchType: "EXACT",
                        value: questionFilterOne?.value,
                      },
                    },
                  },
                  {
                    filter: {
                      fieldName: "customEvent:SurveyQuestion",
                      stringFilter: {
                        matchType: "EXACT",
                        value: questionFilterTwo?.value,
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
      },
      metricAggregations: ["TOTAL"],
    };
  }, [questionFilterOne, questionFilterTwo, fromDate, toDate]);

  useEffect(() => {
    setBusy(true);
    if (!questionFilterOne?.value) return setBusy(false);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query })
      .then(({ data }) => {
        const formattedRows = data[0].rows.map((row) => {
          return {
            question: row.dimensionValues[0].value,
            value: row.dimensionValues[1].value,
            surveyId: row.dimensionValues[2].value,
          };
        });

        const groupedBySurvey = groupBy(formattedRows, "surveyId");

        const completeSurveySubmissions = map(
          groupedBySurvey,
          (answers, surveyId) => {
            const surveyAnswers = {
              surveyId,
            };

            const answerOne = answers.find(
              (a) => a.question === questionFilterOne?.value
            );
            const answerTwo = answers.find(
              (a) => a.question === questionFilterTwo?.value
            );

            if (answerOne) surveyAnswers.answerOne = answerOne.value;
            if (answerTwo) surveyAnswers.answerTwo = answerTwo.value;
            return surveyAnswers;
          }
        ).filter((submission) => submission.answerOne && submission.answerTwo);

        const totalSubmissions = completeSurveySubmissions.length;

        const allPossibleAnswerTwos = uniq(
          completeSurveySubmissions.map((md) => md.answerTwo)
        );

        const submissionsByFirstAnswer = groupBy(
          completeSurveySubmissions,
          "answerOne"
        );

        const graphData = map(
          submissionsByFirstAnswer,
          (submissions, answerOne) => {
            const answerTwos = groupBy(submissions, "answerTwo");

            const datum = {
              yIndex: answerOne,
            };

            forEach(allPossibleAnswerTwos, (at) => {
              datum[at] = 0;
            });

            forEach(answerTwos, (atd, answerTwoName) => {
              datum[answerTwoName] = atd.length;
            });

            return datum;
          }
        );

        setData({
          total: `Total surveys: ${totalSubmissions}`,
          graph: graphData,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [
    functions,
    query,
    questionFilterOne?.value,
    questionFilterTwo?.value,
    fromDate,
    toDate,
  ]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Survey analysis"
        actionText="Select questions"
        keyMetric={data.total}
        unflexedActions
        actionComponent={
          <div className="md:flex items-center">
            <div className="flex-1">
              <QuestionsSelect
                value={questionFilterOne}
                setValue={setQuestionFilterOne}
                disabledOptions={[questionFilterTwo]}
                fromDate={fromDate}
                toDate={toDate}
              />
            </div>
            <div className="my-1 md:mx-2 md:my-0">By</div>
            <div className="flex-1">
              <QuestionsSelect
                value={questionFilterTwo}
                setValue={setQuestionFilterTwo}
                disabledOptions={[questionFilterOne]}
                fromDate={fromDate}
                toDate={toDate}
                initialQuestionIdx={1}
              />
            </div>
          </div>
        }
      >
        <BarChart data={data.graph} groupMode="grouped" xTickRotation={0} />
      </GraphCard>
    </div>
  );
};
