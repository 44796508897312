// export const graphColors = [
//   "#00adef",
//   "#00b9e7",
//   "#00c2cc",
//   "#00c6a2",
//   "#32c770",
//   "#86c43b",
//   "#c4b900",
//   "#ffa600",
// ];

// export const graphColors = [
//   "#1537da",
//   "#ab00c2",
//   "#ec009c",
//   "#ff0073",
//   "#ff464a",
//   "#ff821e",
//   "#ffb300",
//   "#e9dd00",
// ];

export const graphColors = [
  "#4A6BCB",
  "#51BCDA",
  "#45D9A8",
  "#DB6E54",
  "#E04E5E",
  "#D9AC3B",
  "#DEC189",
  "#0B3340",
];
