import { GraphCard, PieChart } from "components";
import { useServices } from "services";
import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import {
  map,
  split,
  take,
  takeRight,
  filter,
  last,
  sumBy,
  groupBy,
  orderBy,
} from "lodash";

export const HotspotDistribution = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ keyMetric: "", graph: [] });
  const [busy, setBusy] = useState(true);
  const query = useMemo(() => {
    return {
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "customEvent:label",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                fieldName: "eventName",
                stringFilter: {
                  matchType: "EXACT",
                  value: "click",
                },
              },
            },
            {
              filter: {
                fieldName: "customEvent:label",
                stringFilter: {
                  matchType: "CONTAINS",
                  value: "$",
                },
              },
            },
            {
              notExpression: {
                filter: {
                  fieldName: "customEvent:label",
                  stringFilter: {
                    matchType: "CONTAINS",
                    value: "$ - Aisle",
                  },
                },
              },
            },
          ],
        },
      },
      orderBys: [
        {
          metric: {
            metricName: "eventCount",
          },
          desc: true,
        },
      ],
      metricAggregations: ["TOTAL"],
    };
  }, [fromDate, toDate]);

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "tourdisc", query })
      .then(({ data }) => {
        const mappedData = map(data[0].rows, (dr) => {
          // const eventName = dr.dimensionValues[1].value;
          const eventName = split(dr.dimensionValues[1].value, "$ - ")[1];
          return {
            id: eventName,
            value: Number(dr.metricValues[0].value),
          };
        });
        const groupedData = groupBy(mappedData, (md) => md.id);
        const orderedGroupedMappedData = orderBy(
          map(groupedData, (value, key) => ({
            id: key,
            value: sumBy(value, "value"),
          })),
          ["value"],
          ["desc"]
        );
        const topTen = take(orderedGroupedMappedData, 10);
        const rest = takeRight(mappedData, mappedData.length - 10);
        const topInRest = filter(rest, { value: last(topTen).value });
        const topResults = [...topTen, ...topInRest];
        const _total = numeral(sumBy(topResults, "value"))
          .format("0,0")
          .replace(/,/g, " ");
        setData({
          total: `Top 10 total hotspots: ${_total}`,
          graph: topResults,
        });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, fromDate, toDate]);

  return (
    <div className={styles}>
      <GraphCard
        loading={busy}
        title="Top 10 Hotspot Distribution"
        keyMetric={data.total}
      >
        <PieChart
          data={data.graph}
          margin={{ top: 15, right: 15, bottom: 15, left: 15 }}
        />
      </GraphCard>
    </div>
  );
};
