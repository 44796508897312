import { Children, isValidElement } from "react";

export const Tabs = ({ children, activeKey, onChange }) => {
  let activeChild = null;
  Children.forEach(children, (child) => {
    if (child?.key === activeKey) activeChild = child;
  });

  return (
    <>
      <div className="flex items-center mb-4">
        {Children.map(children, (child, idx) => {
          if (isValidElement(child)) {
            return (
              <button
                key={child.key}
                onClick={() => onChange(child.key)}
                className={`mr-4 pb-1 border-b hover:text-accent ${
                  child.key === activeKey ? "text-accent" : "border-transparent"
                } `}
              >
                {child.props.tab || "Tab Title"}
              </button>
            );
          }
          return null;
        })}
      </div>
      <div>{activeChild}</div>
    </>
  );
};
