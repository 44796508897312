import { useCallback, useState, useEffect } from "react";

import { Modal, SubmitButton } from "components";
import { useServices } from "services/index";

export const DeleteClientModal = ({ client, close }) => {
  const { db } = useServices();

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const deleteClient = useCallback(() => {
    setLoading(true);
    return db
      .collection("clients")
      .doc(client?.id)
      .delete()
      .then(() => {
        close();
        setLoading(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: DeleteClientModal.jsx ~ line 16 ~ deleteClient ~ err",
          err
        );
        setLoading(false);
      });
  }, [db, close, client?.id]);

  const getProjectsList = useCallback(() => {
    if (!client?.id) return [];
    return db
      .collection("projects")
      .where("clientId", "==", client?.id)
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => projects.push({ id: doc.id, ...doc.data() }));
        setProjects(projects);
      })
      .catch((err) =>
        console.log(
          "🚀 ~ file: ProjectsList.jsx ~ line 21 ~ getProjectsList ~ err",
          err
        )
      );
  }, [db, client?.id]);

  useEffect(() => getProjectsList(), [getProjectsList]);

  return (
    <Modal title="Delete Client" visible={!!client} close={close}>
      {!!projects.length && (
        <>
          <div className="flex-1 flex items-center justify-center">
            This client ({client?.name}) still contains projects. Remove all
            projects from this client to delete it.
          </div>
          <SubmitButton onClick={close}>Close</SubmitButton>
        </>
      )}
      {!projects.length && (
        <>
          <div className="flex-1 flex items-center justify-center">
            Are you sure you want to delete {client?.name}?
          </div>
          <SubmitButton
            disabled={loading}
            loading={loading}
            warning
            onClick={deleteClient}
          >
            Delete
          </SubmitButton>
        </>
      )}
    </Modal>
  );
};
