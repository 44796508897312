import { useCallback, useState, useEffect, useMemo } from "react";
import { map } from "lodash";
import { Formik, Form } from "formik";

import { useServices } from "services/index";
import { Select, SubmitButton, IconButton } from "components";
import { ReactComponent as Delete } from "assets/icons/Delete.svg";

export const ProjectUsers = ({ project, saved }) => {
  const { functions, db } = useServices();

  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");

  const getUserList = useCallback(() => {
    const getUserListFunction = functions.httpsCallable("api/users/getList");
    getUserListFunction()
      .then(({ data }) => {
        setUsers(data.users);
      })
      .catch((err) => console.log(err));
  }, [functions]);

  const saveUsers = useCallback(
    (newUsers) => {
      return db
        .collection("projects")
        .doc(project?.id)
        .update({ users: newUsers })
        .then(() => {
          saved();
        })
        .catch((err) => setError(err.message));
    },
    [db, project?.id, saved]
  );

  const userOptions = useMemo(() => {
    return map(users, (u) => ({
      value: u.uid,
      label: u.displayName,
    }));
  }, [users]);

  useEffect(() => getUserList(), [getUserList]);

  return (
    <>
      <Formik
        initialValues={{ projectUsers: project?.users || [] }}
        onSubmit={({ projectUsers }) => {
          return saveUsers(projectUsers);
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="flex flex-col w-full">
            <div className="mt-4">
              <div className="border-b border-borderColor">Add Users</div>
              <Select
                value=""
                options={userOptions}
                isOptionDisabled={(option) =>
                  values.projectUsers.find((pu) => pu.uid === option.value)
                }
                onChange={(newUser) => {
                  setFieldValue("projectUsers", [
                    ...values.projectUsers,
                    { uid: newUser.value, role: "viewer" },
                  ]);
                }}
              />
              <div className="py-4">
                <div className="">Associated Users</div>
                {values.projectUsers.map((pu, idx) => {
                  return (
                    <div
                      className={`flex items-center justify-between space-y-2 py-2 ${
                        idx !== values.projectUsers.length - 1
                          ? "border-b border-borderColor"
                          : ""
                      }`}
                    >
                      <div>
                        {users.find((user) => user.uid === pu.uid)
                          ?.displayName || "No name found"}
                      </div>
                      <div className="flex items-center">
                        <div
                          role="group"
                          aria-labelledby="role"
                          className="flex items-center space-x-3 mr-4"
                        >
                          <label className="flex space-x-2 items-center">
                            <input
                              type="radio"
                              value="viewer"
                              checked={pu.role === "viewer"}
                              onChange={(e) => {
                                setFieldValue(
                                  "projectUsers",
                                  values.projectUsers.map((user) => ({
                                    ...user,
                                    role:
                                      user.uid === pu.uid
                                        ? e.target.value
                                        : user.role,
                                  }))
                                );
                              }}
                            />
                            <div>Viewer</div>
                          </label>
                          <label className="flex space-x-2 items-center">
                            <input
                              type="radio"
                              value="editor"
                              checked={pu.role === "editor"}
                              onChange={(e) => {
                                setFieldValue(
                                  "projectUsers",
                                  values.projectUsers.map((user) => ({
                                    ...user,
                                    role:
                                      user.uid === pu.uid
                                        ? e.target.value
                                        : user.role,
                                  }))
                                );
                              }}
                            />
                            <div>Editor</div>
                          </label>
                        </div>
                        <IconButton
                          onClick={() => {
                            setFieldValue(
                              "projectUsers",
                              values.projectUsers.filter(
                                (user) => pu.uid !== user.uid
                              )
                            );
                          }}
                        >
                          <Delete className="w-4 h-4 text-warning" />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </div>

              <SubmitButton
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                Save Users
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>

      {!!error && (
        <div className="text-error-800 pt-4 text-center">{error}</div>
      )}
    </>
  );
};
