import { useCallback, useState } from "react";
import { Formik, Form, Field } from "formik";
import { omit } from "lodash";
import slugify from "slugify";

import { useServices } from "services/index";
import { FormikFormField, FormInput, SubmitButton } from "components";

export const ProjectForm = ({ project, saved }) => {
  const { db } = useServices();
  const [error, setError] = useState("");

  const createProject = useCallback(
    (newProject) => {
      return db
        .collection("projects")
        .add(newProject)
        .then(() => {
          saved();
        })
        .catch((err) => setError(err.message));
    },
    [db, saved]
  );

  const saveProject = useCallback(
    (newProject) => {
      return db
        .collection("projects")
        .doc(newProject.id)
        .update(omit(newProject, ["id"]))
        .then(() => {
          saved();
        })
        .catch((err) => setError(err.message));
    },
    [db, saved]
  );

  return (
    <>
      <Formik
        initialValues={project}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = "Required";
          }
          if (!values.propertyId) {
            errors.propertyId = "Required";
          }
          if (!values.slug) {
            errors.slug = "Required";
          }
          return errors;
        }}
        onSubmit={(project) => {
          return project?.id ? saveProject(project) : createProject(project);
        }}
      >
        {({ values, isSubmitting, setFieldValue, handleChange }) => (
          <Form className="flex flex-col w-full">
            <div className="flex">
              <div className="flex-1 mr-2">
                <FormikFormField label="Name" name="name" required>
                  <FormInput
                    name="name"
                    onChange={(e) => {
                      if (!values.useCustomSlug)
                        setFieldValue("slug", slugify(e.target.value || ""));
                      handleChange(e);
                    }}
                  />
                </FormikFormField>
              </div>
              <div className="flex-1 ml-2">
                <FormikFormField label="Property ID" name="propertyId" required>
                  <FormInput name="propertyId" />
                </FormikFormField>
              </div>
            </div>

            <FormikFormField label="Use a custom slug" name="useCustomSlug">
              <Field
                type="checkbox"
                name="useCustomSlug"
                onChange={(e) => {
                  if (values.useCustomSlug)
                    setFieldValue("slug", slugify(values.name));
                  handleChange(e);
                }}
              />
            </FormikFormField>

            <FormikFormField label="Slug" name="slug" required>
              <FormInput
                name="slug"
                disabled={!values.useCustomSlug}
                onChange={(e) => {
                  setFieldValue(
                    "slug",
                    slugify(e.target.value, { trim: false })
                  );
                }}
              />
            </FormikFormField>

            <div className="mt-4">
              <SubmitButton
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {project?.id ? "Save" : "Create"}
              </SubmitButton>
            </div>
          </Form>
        )}
      </Formik>

      {!!error && (
        <div className="text-error-800 pt-4 text-center">{error}</div>
      )}
    </>
  );
};
