import { useState } from "react";
import dayjs from "dayjs";

import { Filters } from "./Filters";
import { SurveysAnsweredPerDay } from "./SurveysAnsweredPerDay";
import { SurveyAnswersDistribution } from "./SurveyAnswersDistribution";
import { TopVideosPlayed } from "./TopVideosPlayed";
import { VideosTable } from "./VideosTable";
import { SurveyGroupedAnswers } from "./SurveyGroupedAnswers";

export const VRKit = () => {
  const [fromDate, setFromDate] = useState(
    dayjs(new Date()).subtract(7, "day").toDate()
  );
  const [toDate, setToDate] = useState(new Date());
  return (
    <>
      <div className="pb-4 mb-6 flex justify-end border-b border-borderColor">
        <Filters
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
        />
      </div>
      <div className="flex flex-wrap">
        <div className="w-full flex flex-wrap p-4 my-4 border border-borderColor rounded-lg relative">
          <div className="absolute bg-background-light px-4 -top-1rem left-0 ml-2 md:ml-4 font-light">
            Videos
          </div>
          <div className="w-full xl:w-1/2 mb-4 xl:mb-0">
            <div className="xl:mr-2 h-full">
              <TopVideosPlayed fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <div className="xl:ml-2 h-full">
              <VideosTable fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-wrap p-4 my-4 border border-borderColor rounded-lg relative space-y-4">
          <div className="absolute bg-background-light px-4 -top-1rem left-0 ml-2 md:ml-4 font-light">
            Surveys
          </div>
          <div className="w-full xl:w-1/2">
            <div className="xl:mr-2 h-full">
              <SurveyAnswersDistribution fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <div className="xl:ml-2 h-full">
              <SurveysAnsweredPerDay fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <div className="xl:mr-2 h-full">
              <SurveyGroupedAnswers fromDate={fromDate} toDate={toDate} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
