import { useState, useEffect, useCallback } from "react";
import { useServices } from "services/index";

import { ProjectsList } from "./ProjectsList";
import { ProjectFormModal } from "./ProjectFormModal";
import { DeleteProjectModal } from "./DeleteProjectModal";

export const Projects = ({ clientId }) => {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [editingProject, setEditingProject] = useState();
  const [deletingProject, setDeletingProject] = useState();

  const { db } = useServices();

  const getProjectsList = useCallback(() => {
    setLoading(true);
    return db
      .collection("projects")
      .where("clientId", "==", clientId)
      .get()
      .then((snapshot) => {
        const projects = [];
        snapshot.forEach((doc) => projects.push({ id: doc.id, ...doc.data() }));
        setProjects(projects);
      })
      .catch((err) =>
        console.log(
          "🚀 ~ file: ProjectsList.jsx ~ line 21 ~ getProjectsList ~ err",
          err
        )
      )
      .finally(() => setLoading(false));
  }, [db, clientId]);

  useEffect(() => getProjectsList(), [getProjectsList]);

  return (
    <div>
      {!editingProject && !deletingProject && (
        <ProjectsList
          loading={loading}
          newProject={() =>
            setEditingProject({
              name: "",
              propertyId: "",
              slug: "",
              users: [],
              clientId,
            })
          }
          setEditingProject={setEditingProject}
          setDeletingProject={setDeletingProject}
          projects={projects}
        />
      )}
      <ProjectFormModal
        project={editingProject}
        saved={() => {
          setEditingProject(undefined);
          getProjectsList();
        }}
        close={() => setEditingProject(undefined)}
      />
      <DeleteProjectModal
        project={deletingProject}
        done={() => {
          setDeletingProject(undefined);
          getProjectsList();
        }}
        close={() => setDeletingProject(undefined)}
      />
    </div>
  );
};
