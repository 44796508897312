import { GraphCard } from "components";
import { useServices } from "services";
import { useEffect, useMemo, useState } from "react";
import numeral from "numeral";
import dayjs from "dayjs";
import { get, each, find } from "lodash";
import AustraliaMap from "react-australia-map";
import { graphColors } from "components";
import { states } from "./States";

export const EngagementMap = ({ styles, fromDate, toDate }) => {
  const { functions } = useServices();
  const [data, setData] = useState({ keyMetric: "", graph: [] });
  const [busy, setBusy] = useState(true);

  const query = useMemo(
    () => ({
      dimensions: [
        { name: "eventName" },
        { name: "region" },
        { name: "country" },
      ],
      metrics: [{ name: "eventCount" }],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
      dimensionFilter: {
        andGroup: {
          expressions: [
            {
              filter: {
                stringFilter: { matchType: "EXACT", value: "user_engagement" },
                fieldName: "eventName",
              },
            },
            {
              filter: {
                stringFilter: { matchType: "EXACT", value: "Australia" },
                fieldName: "country",
              },
            },
          ],
        },
      },
      metricAggregations: ["TOTAL"],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "tourdisc", query })
      .then(({ data }) => {
        const _total = numeral(
          get(data[0].totals[0], "metricValues.0.value", 0)
        )
          .format("0,0")
          .replace(/,/g, " ");
        const _graph = {};

        each(data[0].rows, (dr, index) => {
          const stateName = dr.dimensionValues[1].value;
          const userEngagement = dr.metricValues[0].value;
          const stateAbbreviation = find(states, {
            name: stateName,
          })?.abbreviation;
          _graph[stateAbbreviation] = {
            fill: graphColors[index],
            showLabels: true,
            label: {
              name: `${stateAbbreviation}: ${userEngagement}`,
              textAnchor: stateAbbreviation === "ACT" ? "left" : "middle",
              fontSize: stateAbbreviation === "ACT" ? 8 : 10,
              fill: stateAbbreviation === "TAS" ? "black" : "white",
              x: stateAbbreviation === "ACT" ? "75%" : 0,
              y: stateAbbreviation === "ACT" ? "70%" : 0,
              fontFamily: "inherit",
            },
          };
        });
        setData({ total: `Total engagement: ${_total}`, graph: _graph });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, fromDate, toDate]);

  return (
    <div className={styles}>
      <GraphCard loading={busy} title="User Engagement" keyMetric={data.total}>
        <div className="flex justify-center">
          <AustraliaMap
            width="80%"
            title="User Engagement"
            stroke="white"
            strokeWidth={1}
            customize={data.graph}
            onHover={() => null}
          />
        </div>
      </GraphCard>
    </div>
  );
};
