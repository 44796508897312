import { useState, useEffect } from "react";

import { Modal, Tabs } from "components";

import { ClientForm } from "./ClientForm";
import { Projects } from "./Projects";

export const ClientModal = ({ client, close }) => {
  const [currentTab, setCurrentTab] = useState("details");

  useEffect(() => !client?.id && setCurrentTab("details"), [client?.id]);

  return (
    <Modal
      title={client?.id ? "Edit Client" : "Add Client"}
      visible={!!client}
      close={close}
      width={!client?.id ? "50vw" : undefined}
    >
      <div className="mt-4">
        {!!client?.id && (
          <Tabs activeKey={currentTab} onChange={setCurrentTab}>
            <div tab="Details" key="details">
              <ClientForm client={client} done={close} />
            </div>
            <div tab="Projects" key="projects">
              <Projects clientId={client?.id} />
            </div>
          </Tabs>
        )}
        {!client?.id && <ClientForm client={client} done={close} />}
      </div>
    </Modal>
  );
};
