import { useServices } from "services";
import { useState, useEffect, useMemo } from "react";
import { uniqBy, map, filter, split, upperFirst } from "lodash";
import dayjs from "dayjs";
import { Select } from "components";
import PulseLoader from "react-spinners/PulseLoader";

export const QuestionsSelect = ({
  value,
  setValue,
  fromDate,
  toDate,
  initialQuestionIdx,
  disabledOptions,
}) => {
  const { functions } = useServices();
  const [busy, setBusy] = useState(true);
  const [questionsOptions, setQuestionsOptions] = useState([]);

  const questionsQuery = useMemo(
    () => ({
      dimensions: [{ name: "customEvent:SurveyQuestion" }],
      dateRanges: [
        {
          startDate: dayjs(fromDate).format("YYYY-MM-DD"),
          endDate: dayjs(toDate).format("YYYY-MM-DD"),
        },
      ],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query: questionsQuery })
      .then(({ data }) => {
        const _questions = filter(
          uniqBy(
            map(data[0].rows, (dr) => {
              const optionValue = dr.dimensionValues[0].value;
              const optionLabel = dr.dimensionValues[0].value;
    //          const optionLabel = upperFirst(
  //              split(optionValue, "question_")[1]
//              );
              return {
                label: optionLabel,
                value: optionValue,
              };
            }),
            "value"
          ),
          (v) => v.value !== "(not set)"
        );
        setQuestionsOptions(_questions);
        setValue(_questions[initialQuestionIdx || 0]);
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, setValue, questionsQuery, initialQuestionIdx]);

  return busy ? (
    <PulseLoader color="#D0CFCD" size={10} />
  ) : (
    <Select
      options={questionsOptions}
      value={value}
      isOptionDisabled={(opt) => {
        if (!disabledOptions) return;

        return disabledOptions.find(
          (disabledOpt) => disabledOpt.value === opt.value
        );
      }}
      onChange={setValue}
    />
  );
};

export default QuestionsSelect;
