import MenuItem from "./MenuItem";

export const Menu = ({ menuItems }) =>
  menuItems.map((item) => (
    <div key={item.label}>
      <MenuItem {...item} onClick={item.onClick}>
        {item.label}
      </MenuItem>
      <div className="pl-2">
        {item.subItems
          ? item.subItems.map((subItem) => (
              <MenuItem
                key={subItem.label}
                subItem
                {...subItem}
                onClick={subItem.onClick}
              >
                {subItem.label}
              </MenuItem>
            ))
          : null}
      </div>
    </div>
  ));

export default Menu;
