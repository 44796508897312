import { ReactComponent as Phone } from "assets/icons/Phone.svg";
import PulseLoader from "react-spinners/PulseLoader";

export const InfoCard = ({ heading, subheading, styles, loading }) => {
  return (
    <div
      className={`bg-white rounded-md flex flex-col justify-between h-full ${styles}`}
    >
      <div className="flex items-center justify-between p-3">
        <Phone className="w-6 h-6 xl:w-8 xl:h-8 lg:ml-4 text-accent" />
        <div className="text-right">
          <div className="text-text-medium text-sm xl:text-md">
            {subheading}
          </div>
          <div className="flex items-end justify-end h-8">
            {!loading && <div className="text-xl xl:text-3xl">{heading}</div>}
            <PulseLoader color="#D0CFCD" loading={loading} size={10} />
          </div>
        </div>
      </div>
      {/* <div className="mx-3 py-3 border-t border-borderColor flex items-center text-text-medium">
        <Phone className="w-4 h-4 mr-3" />
        Update Now
      </div> */}
    </div>
  );
};

export default InfoCard;
