import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  appId: "1:1078881999295:web:c2d5c2e9b80f910089e90a",
  projectId: "thinkdigitaldashboard",
  apiKey: "AIzaSyBoglfMOFNuLqJ-yBLJJIP2N7hEk5q2vEY"
  // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // storageBucket: process.env.REACT_APP_FIREBASE_STORAGEB_BUCKET_ID,
  // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};

const app = firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore(app);
export const auth = firebase.auth(app);
export const functions = app.functions("australia-southeast1");
// if (process.env.REACT_APP_DEVELOPMENT) {
//   functions.useEmulator("localhost", 5001);
//   auth.useEmulator("http://localhost:9099");
//   db.useEmulator("localhost", 8080);
// }
