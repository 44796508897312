import { useState, useEffect } from "react";

import { Modal, Tabs } from "components";

import { ProjectForm } from "./ProjectForm";
import { ProjectUsers } from "./ProjectUsers";

export const ProjectFormModal = ({ project, saved, close }) => {
  const [currentTab, setCurrentTab] = useState("details");

  useEffect(() => !project?.id && setCurrentTab("details"), [project?.id]);

  return (
    <Modal
      title={project?.id ? "Edit Project" : "Add Project"}
      visible={!!project}
      close={close}
    >
      <div className="mt-4">
        {!!project?.id && (
          <Tabs activeKey={currentTab} onChange={setCurrentTab}>
            <div tab="Details" key="details">
              <ProjectForm project={project} saved={saved} />
            </div>
            <div tab="Users" key="users">
              <ProjectUsers project={project} saved={saved} />
            </div>
          </Tabs>
        )}
        {!project?.id && <ProjectForm project={project} saved={saved} />}
      </div>
    </Modal>
  );
};
