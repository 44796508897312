import { RangePicker } from "components";

export const Filters = ({ fromDate, setFromDate, toDate, setToDate }) => {
  return (
    <RangePicker
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
    />
  );
};
