import { Scrim } from "components";

export const PublicLayout = ({ children }) => {
  return (
    <div
      className="h-screen w-screen bg-cover bg-center relative flex justify-center items-center"
      style={{ backgroundImage: "url(/images/background.jpg)" }}
    >
      <Scrim></Scrim>
      <div className="relative bg-white rounded-md p-6">{children}</div>
    </div>
  );
};
