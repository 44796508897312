import { useState, useEffect, useMemo } from "react";
import { sumBy, find } from "lodash";
import dayjs from "dayjs";
import numeral from "numeral";

import { GraphCard, LineChart } from "components";
import { useServices } from "services";

export const TopVideosPlayed = ({ fromDate, toDate, styles }) => {
  const { functions } = useServices();

  // const filters = [{ name: "Plays" }, { name: "Downloads" }];
  // const filterOptions = filters.map((opt) => ({
  //   label: opt.name,
  //   value: opt.name,
  // }));

  const [data, setData] = useState({ total: 0, graph: [] });

  const [busy, setBusy] = useState(false);

  const query = useMemo(
    () => ({
      dimensions: [
        {
          name: "eventName",
        },
        {
          name: "date",
        },
      ],
      metrics: [
        {
          name: "eventCount",
        },
      ],
      dateRanges: [
        {
          startDate: fromDate
            ? dayjs(fromDate).format("YYYY-MM-DD")
            : "30daysAgo",
          endDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : "today",
        },
      ],
      dimensionFilter: {
        filter: {
          fieldName: "eventName",
          stringFilter: {
            matchType: "EXACT",
            value: "DiscoveryTours_VideoPlayed",
          },
        },
      },
      orderBys: [
        {
          dimension: { orderType: "ALPHANUMERIC", dimensionName: "date" },
          desc: true,
        },
      ],
    }),
    [fromDate, toDate]
  );

  useEffect(() => {
    setBusy(true);
    const runReport = functions.httpsCallable("api/runReport");
    runReport({ project: "vrkit", query })
      .then(({ data }) => {
        const graphData = data[0].rows.map((row) => ({
          x: dayjs(row.dimensionValues[1].value, "YYYYMMDD").format("DD MMM"),
          y: row.metricValues[0].value,
        }));

        const total = numeral(sumBy(graphData, (d) => Number(d.y)))
          .format("0,0")
          .replace(/,/g, " ");

        const differenceInDays = dayjs(toDate).diff(dayjs(fromDate), "day");

        const allDays = [];
        for (let i = 1; i <= differenceInDays + 1; i++) {
          const date = dayjs(toDate)
            .subtract(differenceInDays - i + 1, "day")
            .format("DD MMM");

          allDays.push({
            x: date,
            y: find(graphData, (gr) => gr.x === date)?.y || 0,
          });
        }

        setData({ total, graph: [{ id: "VR Video", data: allDays }] });
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setBusy(false);
      });
  }, [functions, query, fromDate, toDate]);

  return (
    <GraphCard
      loading={busy}
      title="Videos Played / Downloaded"
      keyMetric={`Total videos: ${data.total}`}
    >
      <LineChart data={data.graph} />
    </GraphCard>
  );
};
